// Blog.js
import React from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

const IframeContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const Blog = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Notizie sul mondo del lavoro
      </Typography>
      <IframeContainer>
        <Box
          component="iframe"
          src="https://news.google.com/topics/CAAqKAgKIiJDQkFTRXdvS0wyMHZNR0p2YzJrMmR6bUtLQUFQAQ?hl=it&gl=IT&ceid=IT:it"
          width={isMobile ? "100%" : "90%"}
          height={isMobile ? "100vh" : "80vh"}
          sx={{
            border: 'none',
            borderRadius: 2,
            boxShadow: 3,
          }}
        />
      </IframeContainer>
    </Container>
  );
};

export default Blog;
