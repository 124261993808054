import React, { useState, useEffect } from 'react';
import {
  Autocomplete, TextField, Grid, Paper, Avatar, Button, Select, MenuItem, FormControl, InputLabel, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TablePagination, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions, Pagination, PaginationItem, Box, Snackbar, CircularProgress,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Badge } from '@mui/material';
import { Container, styled } from '@mui/system';
import axios from 'axios';
import utils from '../../utils';

// Styled component per il titolo
const Title = styled(Typography)({
  textAlign: 'center',
  marginBottom: '24px',
  fontWeight: 'bold',
  color: 'black'
});

// Styled table head con riga scura
const StyledTableHead = styled(TableRow)({
  backgroundColor: '#424242',
  '& th': {
    color: 'white',
    fontWeight: 'bold',
  },
});

// Styled component per la TableCell per centrare i dati nel dialog delle richieste di modifica
const StyledTableCell = styled(TableCell)({
  textAlign: 'center',
});

const OpPagBustePaghe = () => {
  const now = new Date();

  const anni = ['2024'];
  while (now.getFullYear() > anni[anni.length - 1]) {
    anni.push((Number(anni[anni.length - 1]) + 1).toString());
  }

  const nowMonth = now.getMonth();

  const [aziende, setAziende] = useState([]);
  const [selectedAzienda, setSelectedAzienda] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [meseSelezionato, setMeseSelezionato] = useState(nowMonth == 0 ? 11 : nowMonth - 1);
  const [annoSelezionato, setAnnoSelezionato] = useState(nowMonth == 0 ? now.getFullYear() - 1 : now.getFullYear());
  const [statoBusta, setStatoBusta] = useState('Tutte');
  const [pagina, setPagina] = useState(0);
  const [righePerPagina, setRighePerPagina] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [bustaDaApprovare, setBustaDaApprovare] = useState(null);
  const [bustePaga, setBustePaga] = useState([]);
  const [doc, setDoc] = useState(null);
  const [docSplittato, setDocSplittato] = useState(null);
  const [richiestaModifica, setRichiestaModifica] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [openApproveAllDialog, setOpenApproveAllDialog] = useState(false);
  const [tutteBozzeApprovate, setTutteBozzeApprovate] = useState(false);
  const [richiesteModificaCount, setRichiesteModificaCount] = useState(0);
  // Stato per gestire l'apertura del dialog delle richieste di modifica
  const [openModificaDialog, setOpenModificaDialog] = useState(false);

  useEffect(() => {
    const count = aziende.reduce((a, c) => a + c.richieste, 0);
    setRichiesteModificaCount(count);
  }, [aziende]);

  // Funzione per caricare le aziende dal backend
  const caricaAziende = async (a, m) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/aziendeperpaghe?a=${a}&m=${m}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(error);
      }
    } catch (error) {
      setError('Errore nel caricamento delle aziende: ' + error.message);
    }
    setLoading(false);
  };

  // Funzione per caricare le buste paga dal backend
  const caricaBustePaga = async (a, m, id) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/paghe?anno=${a}&mese=${m}&azienda=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setBustePaga(res.data.paghe);
        setDoc(res.data.doc);
        setDocSplittato(res.data.doc_splittato);
        setTutteBozzeApprovate(res.data.paghe.every(item => item.definitiva === true));
      } else {
        setError(error);
      }
    } catch (error) {
      setError('Errore nel caricamento delle buste paga: ' + error.message);
    }
    setLoading(false);
  };

  // Gestione apertura/chiusura dialog di approvazione
  const handleOpenApproveDialog = (busta) => {
    setBustaDaApprovare(busta);
    setOpenApproveDialog(true);
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
    setBustaDaApprovare(null);
  };

  // Funzione per approvare la bozza
  const handleApproveBozza = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/api/setpaghedefinitiva', {
        anno: annoSelezionato, mese: meseSelezionato, azienda: selectedAzienda,
        index: bustaDaApprovare.index
      }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        caricaBustePaga(annoSelezionato, meseSelezionato, selectedAzienda); // Aggiorna la lista delle buste paga dopo l'approvazione
      } else {
        setError(error);
      }
      handleCloseApproveDialog();
    } catch (error) {
      setError('Errore nell\'approvazione della bozza: ' + error.message);
    }
    setLoading(false);
  };

  // Funzione per caricare i file sul backend
  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return; // Handle case where no file is selected

    const payload = new FormData();
    const json = {
      anno: annoSelezionato,
      mese: meseSelezionato,
      userId: selectedAzienda,
      index,
      doc: file.name
    };

    payload.append("doc", file);
    payload.append("data", JSON.stringify(json));

    setLoading(true);
    try {
      const response = await axios.post(`/api/setpaghedocument${index >= 0 ? 'o' : 'i'}`, payload, {
        headers: {
          ...utils.getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        },
      });

      // Assuming the backend sends back a success response
      if (response.data.success) {
        caricaBustePaga(annoSelezionato, meseSelezionato, selectedAzienda); // Update the list of payslips after upload
      } else {
        setError('Errore nel caricamento del file: ' + (response.data.message || ''));
      }
    } catch (error) {
      setError('Errore nel caricamento del file: ' + error.message);
      console.error('Upload error:', error); // Log error for debugging
    } finally {
      setLoading(false);
    }
  };

  // Funzione per scaricare le buste paga dal backend
  const handleDownloadBustaPaga = async (docId) => {
    setLoading(true);
    try {
      await utils.opDownload(docId);
    } catch (error) {
      console.error(error);
      setError('File non trovato.');
    }
    setLoading(false);
  };

  // Gestione apertura/chiusura dialog
  const handleOpenDialog = (busta) => {
    setRichiestaModifica(busta.richiesta || ''); // Passa il testo della richiesta di modifica se presente
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Filtraggio delle buste paga in base al mese e all'anno selezionato
  const busteFiltrate = bustePaga
    .filter(busta => busta.dipendente.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(busta => {
      if (statoBusta == 'Tutte') {
        return true;
      } else if (statoBusta == 'Bozza') {
        return !busta.definitiva;
      } else {
        return busta.definitiva;
      }
    })

  // Carica le aziende, dipendenti e buste paga al montaggio del componente
  useEffect(() => {
    caricaAziende(annoSelezionato, meseSelezionato);
  }, [annoSelezionato, meseSelezionato]);

  useEffect(() => {
    if (selectedAzienda && (meseSelezionato + 1) && annoSelezionato) {
      caricaBustePaga(annoSelezionato, meseSelezionato, selectedAzienda);
    }
  }, [annoSelezionato, meseSelezionato, selectedAzienda]);

  // Gestione Paginazione fuori dal Container
  const handlePageChange = (event, value) => {
    setPagina(value - 1); // Pagination di MUI è 1-based, quindi sottraiamo 1
  };

  const handleRowsPerPageChange = (event) => {
    setRighePerPagina(parseInt(event.target.value, 10));
    setPagina(0); // Resetta la pagina alla prima quando cambi il numero di righe per pagina
  };

  // Funzione per approvare tutte le bozze
  const handleApproveTutteLeBozze = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/api/setpaghedefinitiva', {
        anno: annoSelezionato, mese: meseSelezionato, azienda: selectedAzienda,
        index: -1
      }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        caricaBustePaga(annoSelezionato, meseSelezionato, selectedAzienda);
      } else {
        setError(error);
      }
      setOpenApproveAllDialog(false);
    } catch (error) {
      setError('Errore nell\'approvazione della bozza: ' + error.message);
    }
    setLoading(false);
  };

  // Funzione per aprire l'anteprima del PDF nel browser
  const handlePreview = (docId) => {
    try {
      utils.opOpenPdfInNewTab(docId);
    } catch (error) {
      setError('Errore nell\'anteprima del PDF: ' + error.message);
      console.error('Errore nell\'anteprima della busta paga:', error);
    }
  };

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} marginTop={2}>
          <Title variant="h4">Buste Paga</Title>
          <Typography
            variant="body1"
            style={{ fontStyle: 'italic', color: '#888', textAlign: 'center', marginBottom: '8px', marginTop: '-16px' }}
          >
            <span style={{ marginRight: '8px' }}>Richieste di Modifica:</span>
            <Box component="span" sx={{ display: 'inline-block', marginLeft: '8px', cursor: 'pointer', color: 'blue' }} onClick={() => setOpenModificaDialog(true)}>
              <Badge
                showZero={true}
                badgeContent={richiesteModificaCount}
                sx={{
                  '.MuiBadge-badge': {
                    backgroundColor: richiesteModificaCount === 0 ? 'green' : 'orange', // Verde per 0 richieste, arancione altrimenti
                    color: 'white', // Testo bianco nel badge
                  },
                }}
              />
            </Box>
          </Typography>
        </Grid>



        {/* Selezione Azienda */}
        <Grid item xs={12} sm={6} md={2} sx={{ mb: 2 }}>
          {aziende.length ? (
            <Autocomplete
              id="azienda"
              value={selectedAzienda}
              onChange={(event, newValue) => {
                setSelectedAzienda(newValue);
              }}
              options={aziende.map((az) => az.id)}
              getOptionLabel={(id) => {
                const a = aziende.filter(az => az.id == id)[0];
                return `${a.nome} (${a.id})${a.richieste ? ` [${a.richieste}]` : ''}`;
              }}
              renderInput={(params) => <TextField {...params} label="Azienda" variant="outlined" />}
            />
          ) : (
            <div />
          )}
        </Grid>

        {/* Selezione Dipendente */}
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            value={searchTerm}
            onChange={(event) => {
              const newValue = event.target.value;
              setSearchTerm(newValue);
            }}
            label="Dipendente"
            variant="outlined"
            fullWidth
            sx={{ flexGrow: 1, mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}
          />
        </Grid>

        {/* Selezione Anno */}
        <Grid item xs={12} sm={6} md={2}>
          <Select value={annoSelezionato} onChange={(e) => setAnnoSelezionato(e.target.value)} fullWidth>
            {anni.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
          </Select>
        </Grid>

        {/* Selezione Mese */}
        <Grid item xs={12} sm={6} md={2}>
          <Select value={meseSelezionato} onChange={(e) => setMeseSelezionato(e.target.value)} fullWidth>
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Selezione Stato Busta */}
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel id="stato-busta-label">Stato Busta</InputLabel>
            <Select
              labelId="stato-busta-label"
              id="stato-busta"
              value={statoBusta}
              label="Stato Busta"
              onChange={(e) => setStatoBusta(e.target.value)}
            >
              <MenuItem value="Tutte">Tutte</MenuItem>
              <MenuItem value="Bozza">Bozza</MenuItem>
              <MenuItem value="Definitiva">Definitiva</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Upload e Download Icon */}
        <Grid item xs={12} sm={6} md={2} display="flex" justifyContent="center" alignItems="center" gap={0}>
          <Tooltip title="Approva tutte le Bozze">
            <IconButton
              color={tutteBozzeApprovate ? 'success' : 'primary'}
              onClick={() => setOpenApproveAllDialog(true)}
              disabled={!busteFiltrate.some(busta => !busta.definitiva)}
            >
              <CheckCircleIcon style={{ fontSize: 35 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Carica il PDF unico di tutte le buste">
            <IconButton color="primary" component="label">
              <UploadIcon style={{ fontSize: 35 }} />
              <input type="file" hidden onChange={(e) => handleFileUpload(e, -1)} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Scarica il PDF unico di tutte le buste">
            <IconButton
              color={doc ? 'success' : 'inherit'}
              onClick={() => handleDownloadBustaPaga(doc)}
              disabled={!doc}
            >
              <DownloadIcon style={{ fontSize: 35 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Scarica i PDF Singoli di tutte le Buste">
            <IconButton
              color={docSplittato ? 'success' : 'inherit'}
              onClick={() => handleDownloadBustaPaga(docSplittato)}
              disabled={!docSplittato}
            >
              <DownloadForOfflineIcon style={{ fontSize: 35 }} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/* Anagrafica Dipendente Selezionato 
        {dipendenteSelezionato && (
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ padding: '8px', display: 'flex', alignItems: 'flex-start', marginTop: '8px' }}>
              <Avatar style={{ marginTop: '8px' }}>
                {dipendenteSelezionato.nome.split(' ').map(n => n[0]).join('')}
              </Avatar>
              <div style={{ marginLeft: '16px' }}>
                <h3>{`${dipendenteSelezionato.nome} ${dipendenteSelezionato.cognome}`}</h3>
                {dipendenteSelezionato.anagrafica && (
                  <>
                    <p>{`Ruolo: ${dipendenteSelezionato.anagrafica.ruolo}`}</p>
                    <p>{`Orario: ${dipendenteSelezionato.anagrafica.orario}`}</p>
                  </>
                )}
              </div>
            </Paper>
          </Grid>
        )} */}

        {/* Tabella Buste Paga */}
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="buste paga table">
              <TableHead sx={{ '& th': { textAlign: 'center', width: '20%' } }}>
                <StyledTableHead>
                  <TableCell>Dipendente</TableCell>
                  <TableCell>Stato</TableCell>
                  <TableCell align="center">Richieste</TableCell>
                  <TableCell align="center">Azioni</TableCell>
                </StyledTableHead>
              </TableHead>
              <TableBody sx={{ '& td': { textAlign: 'center', width: '20%' } }}>
                {busteFiltrate
                  .slice(pagina * righePerPagina, pagina * righePerPagina + righePerPagina)
                  .map((busta, index) => (
                    <TableRow key={index}>
                      <TableCell>{busta.dipendente}</TableCell>
                      <TableCell>{busta.definitiva ? 'Definitiva' : 'Bozza'}</TableCell>
                      <TableCell align="center">
                        <>
                          {busta.definitiva ?
                            <Tooltip title="Approvata">
                              <CheckCircleIcon style={{ color: 'green' }} />
                            </Tooltip>
                            :
                            <Tooltip title="Approva Bozza">
                              <IconButton color="primary" onClick={() => handleOpenApproveDialog(busta)}>
                                <CheckCircleIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          {(!busta.definitiva) && busta.richiesta ?
                            <Tooltip title="Richiesta di Modifica">
                              <IconButton color="warning" onClick={() => handleOpenDialog(busta)}>
                                <WarningIcon />
                              </IconButton>
                            </Tooltip> : <div />}
                        </>
                      </TableCell>
                      <TableCell align="center">
                        {/* Bottone Upload */}
                        <Tooltip title="Carica PDF">
                          <IconButton color="primary" component="label" disabled={busta.definitiva}>
                            <UploadIcon />
                            <input type="file" hidden onChange={(e) => handleFileUpload(e, busta.index)} />
                          </IconButton>
                        </Tooltip>
                        {/* Bottone Download */}
                        <Tooltip title="Scarica PDF">
                          <IconButton color={busta.documento ? 'success' : 'inherit'} onClick={() => handleDownloadBustaPaga(busta.documento)} disabled={!busta.documento}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Bottone Anteprima */}
                        <Tooltip title="Visualizza Anteprima">
                          <IconButton color={busta.documento ? 'primary' : 'inherit'} onClick={() => handlePreview(busta.documento)} disabled={!busta.documento}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Paginazione */}
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={2}>
            <FormControl variant="outlined" size="small">
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={righePerPagina}
                onChange={handleRowsPerPageChange}
                variant="outlined"
                style={{ marginRight: '8px', maxWidth: '80px', height: '35px' }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              count={Math.ceil(busteFiltrate.length / righePerPagina)} // Numero totale di pagine
              page={pagina + 1} // `Pagination` usa un sistema a base-1
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component="button"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Dialog per le richieste di modifica */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Richieste di Modifica</DialogTitle>
        <DialogContent>
          <p>{richiestaModifica}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per le richieste di modifica nel dettaglio */}
      <Dialog open={openModificaDialog} onClose={() => setOpenModificaDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Richieste di Modifica</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
            <Table aria-label="richieste modifica table">
              <TableHead>
                <StyledTableHead>
                  <StyledTableCell>Ragione Sociale</StyledTableCell>
                  <StyledTableCell>Numero Richieste</StyledTableCell>
                </StyledTableHead>
              </TableHead>
              <TableBody>
                {aziende.filter(azienda => azienda.richieste > 0).map((azienda, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{azienda.nome}</StyledTableCell>
                    <StyledTableCell>{azienda.richieste}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModificaDialog(false)} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog per l'approvazione della bozza */}
      <Dialog open={openApproveDialog} onClose={handleCloseApproveDialog}>
        <DialogTitle>Conferma Approvazione</DialogTitle>
        <DialogContent>
          <p>Sei sicuro di voler approvare la bozza per {bustaDaApprovare && bustaDaApprovare.dipendente}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={handleApproveBozza} color="primary">
            Approva
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per l'approvazione di tutte le bozze */}
      <Dialog open={openApproveAllDialog} onClose={() => setOpenApproveAllDialog(false)}>
        <DialogTitle>Conferma Approvazione di Tutte le Bozze</DialogTitle>
        <DialogContent>
          <p>Sei sicuro di voler approvare tutte le bozze presenti? L'azione non può essere annullata.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenApproveAllDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleApproveTutteLeBozze} color="primary">
            Approva Tutte
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar per gli errori */}
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}

      {/* CircularProgress per il caricamento */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default OpPagBustePaghe;
