import React, { useEffect, useState } from "react";
import {
  Typography, Grid, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, Select, MenuItem, Button,
  Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ButtonBase, Tooltip, TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon } from '@mui/icons-material';
import axios from "axios";
import utils from "../../utils";
import {
  ResponsiveContainer, PieChart, Pie, Cell,
} from "recharts";
// import { useNavigate } from "react-router-dom"; // Importa useNavigate

const COLORS = ["#0088a8", "#035974"];
const dotStyle = {
  display: 'inline-block',
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginRight: '5px',
  marginLeft: '10px',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
};

const OpPagChart = () => {
  // const navigate = useNavigate();

  const now = new Date();
  const currentMonth = now.getMonth();
  const yearMap = {};
  yearMap[currentMonth] = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  yearMap[previousMonth] = currentMonth === 0 ? now.getFullYear() - 1 : now.getFullYear();

  const [presenzeData, setPresenzeData] = useState([]);
  const [pagheData, setPagheData] = useState([]);
  const [todoPaghe, setTodoPaghe] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(previousMonth);
  // Aggiunto lo stato per il dialog di conferma
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  // Aggiunto lo stato per il dialog modale delle aziende
  const [openAziendeDialog, setOpenAziendeDialog] = useState(false);
  const [aziendeList, setAziendeList] = useState([]);

  const [sortAsc, setSortAsc] = useState(true);
  const [sortField, setSortField] = useState("nome");
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadCharts();
  }, [selectedMonth]);

  const loadCharts = async () => {
    try {
      const res = await axios.get(`/api/presenzestats?anno=${yearMap[selectedMonth]}&mese=${selectedMonth}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats, todo } = res.data;
      if (success) {
        setPresenzeData([{ name: "Ricevute", value: stats.ricevute.length, aziende: stats.ricevute }, { name: "In attesa", value: stats.daRicevere.length, aziende: stats.daRicevere }]);
        setPagheData([{ name: "Completate", value: stats.completate.length, aziende: stats.completate }, { name: "Da fare", value: stats.daCompletare.length, aziende: stats.daCompletare }]);
        setTodoPaghe(todo);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  // Funzione per confermare l'annullamento
  const handleConfirmDelete = async () => {
    setOpenDialog(false);
    setIsLoading(true);
    try {
      const res = await axios.post(
        `/api/annullainviopresenze`,
        { mese: selectedMonth, anno: yearMap[selectedMonth], id: selectedUserId },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  // Funzione Pie Clic
  const handlePieClick = (data, index) => {
    setAziendeList(data.aziende);
    setOpenAziendeDialog(true);
  };

  // Funzione per ordinare colonna da A-Z e Z-A
  const handleSort = (field) => {
    // Cambia l'ordinamento solo se la colonna corrente è uguale alla colonna cliccata
    if (sortField === field) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true); // Resetta a ordine crescente quando si cambia colonna
      setSortField(field);
    }

    const sortedData = [...todoPaghe].sort((a, b) => {
      if (sortAsc) return a[field] > b[field] ? 1 : -1;
      return a[field] < b[field] ? 1 : -1;
    });
    setTodoPaghe(sortedData);
  };

  return (
    <Grid container spacing={0} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="center" gutterBottom>
          Presenze
        </Typography>
        <div style={{ height: 200 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={presenzeData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={60}
                  paddingAngle={5}
                  cornerRadius={5}
                  fill="#8884d8"
                  label
                  onClick={handlePieClick}
                >
                  {presenzeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          style={{ fontStyle: 'italic', color: 'grey' }}
        >
          <span style={{ ...dotStyle, backgroundColor: COLORS[0] }}></span>
          Presenze Ricevute
          <span style={{ ...dotStyle, backgroundColor: COLORS[1] }}></span>
          Presenze in Attesa
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="center" gutterBottom>
          Paghe
        </Typography>
        <div style={{ height: 200 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pagheData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={20}
                  outerRadius={60}
                  paddingAngle={5}
                  cornerRadius={5}
                  fill="#8884d8"
                  label
                  onClick={handlePieClick}
                >
                  {pagheData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <Typography
          variant="body2"
          align="center"
          gutterBottom
          style={{ fontStyle: 'italic', color: 'grey' }}
        >
          <span style={{ ...dotStyle, backgroundColor: COLORS[0] }}></span>
          Paghe Completate
          <span style={{ ...dotStyle, backgroundColor: COLORS[1] }}></span>
          Paghe Da fare
        </Typography>
      </Grid>
      <Grid item xs={12}>

        <Divider sx={{ my: 2 }} />

        {/* Tabella Paghe da fare con mese corrente ed elaborazione */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          marginTop={3}
          sx={{
            padding: { xs: 2, sm: 0 }, // Aggiungi padding solo sui dispositivi piccoli
          }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              label="Cerca Azienda"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="h5" align="center" gutterBottom>
              Paghe da Fare
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                fullWidth
              >
                <MenuItem value={currentMonth}>
                  {new Date(0, currentMonth).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())} (Corrente)
                </MenuItem>
                <MenuItem value={previousMonth}>
                  {new Date(0, previousMonth).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())} (Elaborazione)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <TableContainer style={{ borderRadius: '8px', marginTop: '20px', }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#333' }}>

                <Tooltip title={sortField === 'nome' && sortAsc ? "Ordina A-Z" : "Ordina Z-A"}>
                  <TableCell
                    style={{ color: '#fff', textAlign: 'center', cursor: 'pointer', width: '20%' }}
                    onClick={() => handleSort('nome')}
                  >
                    Azienda {sortField === 'nome' ? (sortAsc ? '▲' : '▼') : ''}
                  </TableCell>
                </Tooltip>

                <TableCell style={{ color: '#fff', width: '20%', textAlign: 'center' }}>N. Dipendenti</TableCell>

                <Tooltip title={sortField === 'dataInvio' && sortAsc ? "Ordina per Data Meno Recente" : "Ordina per Data Più Recente"}>
                  <TableCell
                    style={{ color: '#fff', textAlign: 'center', cursor: 'pointer', width: '20%' }}
                    onClick={() => handleSort('dataInvio')}
                  >
                    Data Invio {sortField === 'dataInvio' ? (sortAsc ? '▲' : '▼') : ''}
                  </TableCell>
                </Tooltip>

                <TableCell style={{ color: '#fff', width: '20%', textAlign: 'center' }}>Presa in Carico da</TableCell>
                <TableCell style={{ color: '#fff', width: '10%', textAlign: 'center' }}>Stato</TableCell>
                <TableCell style={{ color: '#fff', width: '10%', textAlign: 'center' }}>Annulla</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todoPaghe.filter((row) =>
                row.nome.toLowerCase().includes(searchTerm.toLowerCase())  // Filtro in base al nome azienda
              ).map((row) => (
                <TableRow key={row.nome}>
                  <TableCell align="center">
                    <ButtonBase
                      component={Link}
                      to={`/op/paghe/presenze?id=${row.userId}&m=${selectedMonth}&y=${yearMap[selectedMonth]}`}
                    >{row.nome}</ButtonBase></TableCell>
                  <TableCell align="center">{row.dipendenti}</TableCell>
                  <TableCell align="center">{row.dataInvio ? utils.revDate(row.dataInvio) : ""}</TableCell>
                  <TableCell align="center">{row.presaInCarico ? row.presaInCarico : ""}</TableCell>
                  <TableCell align="center">

                    {!row.da_controllare ? (
                      <Tooltip title="Controllata">
                        <CheckCircleIcon style={{ color: 'green' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Da controllare">
                        <WarningIcon style={{ color: 'orange' }} />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      disabled={row.presaInCarico}
                      color="error"
                      onClick={() => {
                        setSelectedUserId(row.userId);
                        setOpenDialog(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Dialog Conferma Annullamento */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Conferma Annullamento"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler annullare l'invio delle presenze per quest'azienda?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Dettaglio Aziende Pie */}
      <Dialog
        open={openAziendeDialog}
        onClose={() => setOpenAziendeDialog(false)}
        aria-labelledby="aziende-dialog-title"
        aria-describedby="aziende-dialog-description"
      >
        <DialogTitle id="aziende-dialog-title">{"Lista Aziende"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="aziende-dialog-description">
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
              {aziendeList.map((azienda, index) => (
                <li key={index}>{azienda}</li>
              ))}
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAziendeDialog(false)} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default OpPagChart;
