import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Input,
  Box,
  Container,
  Typography,
  Paper,
  CircularProgress,
  Autocomplete,
  FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import JSZip from 'jszip';
import axios from "axios";
import utils from "../../utils";
import ContrSelectDipendente from './ContrSelectDipendente';

const PagMalattia = () => {
  const [formValues, setFormValues] = useState({
    nome: "",
    cognome: "",
    dataNascita: "",
    codiceFiscale: "",
    dataInizioMalattia: null,
    dataFineMalattia: null,
    note: "",
    certificatoMedico: null,
    cartaIdentita: null,
    codiceFiscaleUpload: null,
    permessoSoggiorno: null,
    certificatoResidenza: null,
    documentoUnilav: null,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [aziende, setAziende] = useState([]);
  const [aziendaId, setAziendaId] = useState(0);
  const [isMaster, setIsMaster] = useState(false);

  // Effetto per il caricamento dei dati iniziali
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success) {
          setIsMaster(me.is_master);
          if (me.is_master) {
            loadSottoAziende();
          }
          setLoading(false);
          return; // Rimani sulla pagina
        } else if (error) {
          setStatusMessage(error);
        }
      }
    } catch (error) {
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadSottoAziende = async () => {
    try {
      const res = await axios.get(`/api/sottoaziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDateChange = (name, newValue) => {
    if (!newValue) {
      setFormValues({
        ...formValues,
        [name]: null,
      });
      return;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    setFormValues({
      ...formValues,
      [name]: file,
    });
  };

  const handleSubmit = async (e) => {
    setStatusMessage("");
    e.preventDefault();

    if (!formValues.dataInizioMalattia || !formValues.dataFineMalattia) {
      setStatusMessage("Inserire data inzio e fine malattia");
      return;
    }

    if (formValues.dataFineMalattia < formValues.dataInizioMalattia) {
      setStatusMessage(
        "La data di fine malattia deve essere successiva alla data di inizio malattia"
      );
      return;
    }

    const json = {
      tipo: "malattia",
      nome: formValues.nome,
      cognome: formValues.cognome,
      cod_fiscale: formValues.codiceFiscale,
      data_nascita: formValues.dataNascita,
      data_inizio_malattia: formValues.dataInizioMalattia,
      data_fine_malattia: formValues.dataFineMalattia,
      note: formValues.note,
      azienda: formValues.azienda,

      doc_malattia: formValues.certificatoMedico ?
        formValues.certificatoMedico.name : null,
      doc_carta: formValues.cartaIdentita
        ? formValues.cartaIdentita.name
        : null,
      doc_cf: formValues.codiceFiscaleUpload
        ? formValues.codiceFiscaleUpload.name
        : null,
      doc_permesso: formValues.permessoSoggiorno
        ? formValues.permessoSoggiorno.name
        : null,
      doc_residenza: formValues.certificatoResidenza
        ? formValues.certificatoResidenza.name
        : null,
      doc_unilav: formValues.documentoUnilav
        ? formValues.documentoUnilav.name
        : null,
    };

    const payload = new FormData();

    if (formValues.certificatoMedico) {
      payload.append("malattia", formValues.certificatoMedico.file);
    }
    if (formValues.cartaIdentita) {
      payload.append("carta", formValues.cartaIdentita.file);
    }
    if (formValues.codiceFiscaleUpload) {
      payload.append("cf", formValues.codiceFiscaleUpload.file);
    }
    if (formValues.permessoSoggiorno) {
      payload.append("ps", formValues.permessoSoggiorno.file);
    }
    if (formValues.certificatoResidenza) {
      payload.append("cr", formValues.certificatoResidenza.file);
    }
    if (formValues.documentoUnilav) {
      payload.append("ul", formValues.documentoUnilav.file);
    }

    payload.append("data", JSON.stringify(json));

    try {
      setLoading(true);
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setLoading(false);
      if (success) {
        window.location.href = "/success";
      } else {
        setStatusMessage(error);
        return;
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 413) {
        setStatusMessage(
          "I files sono troppo grandi. Il limite massimo è di 100 MB."
        );
      } else {
        setStatusMessage(
          "Si è verificato un errore imprevisto sul nostro server."
        );
      }
    }
  };

  const handleMultiFileChange = (e, name) => {
    const files = e.target.files;

    if (files.length == 1) {
      setFormValues({
        ...formValues,
        [name]: { name: files[0].name, file: files[0] },
      });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setFormValues({
        ...formValues,
        [name]: { name: `${name}.zip`, file: zippedBlob },
      });
    });
  }

  return (
    <Container>
      <Typography variant="h4" align="center" mt={3}>
        Pratica di Malattia
      </Typography>

      {/* Selettore Dipendente */}
      <ContrSelectDipendente setFormValues={setFormValues} aziendaId={aziendaId} />

      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <form className="malattia-form" onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              display="grid"
              gap={2}
              borderRadius={5}
              border="1px solid #ccc"
              padding={3}
              marginX="auto"
              gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
            >
              <TextField
                label="Nome"
                type="text"
                name="nome"
                value={formValues.nome}
                onChange={handleInputChange}
                required
                title="Inserisci il tuo nome"
              />
              <TextField
                label="Cognome"
                type="text"
                name="cognome"
                value={formValues.cognome}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Data di Nascita"
                type="text"
                name="dataNascita"
                value={formValues.dataNascita}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Codice Fiscale"
                type="text"
                name="codiceFiscale"
                value={formValues.codiceFiscale}
                onChange={handleInputChange}
                required
              />
              <DatePicker
                label="Data Inizio Malattia *"
                format="DD/MM/YYYY"
                value={formValues.dataInizioMalattia}
                onChange={(newValue) =>
                  handleDateChange("dataInizioMalattia", newValue)
                }
                required
              />
              <DatePicker
                label="Data Fine Malattia *"
                format="DD/MM/YYYY"
                value={formValues.dataFineMalattia}
                onChange={(newValue) =>
                  handleDateChange("dataFineMalattia", newValue)
                }
              />
              <TextField
                label="Note"
                name="note"
                value={formValues.note}
                onChange={handleInputChange}
                multiline
                rows={5}
                style={{ marginBottom: "20px" }}
              />

              <div style={{ marginBottom: "10px" }}></div>

              <label htmlFor="certificatoMedico">Certificato Medico</label>
              <input
                type="file"
                name="certificatoMedico"
                onChange={(e) => handleMultiFileChange(e, "certificatoMedico")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="cartaIdentita">Carta d'Identità <span style={{ color: "red" }}>*</span></label>
              <input
                type="file"
                name="cartaIdentita"
                onChange={(e) => handleMultiFileChange(e, "cartaIdentita")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="codiceFiscaleUpload">Codice Fiscale <span style={{ color: "red" }}>*</span></label>
              <input
                type="file"
                name="codiceFiscaleUpload"
                onChange={(e) => handleMultiFileChange(e, "codiceFiscaleUpload")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="permessoSoggiorno">
                Permesso di Soggiorno (se extra UE) <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="permessoSoggiorno"
                onChange={(e) => handleMultiFileChange(e, "permessoSoggiorno")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="certificatoResidenza">
                Certificato di Residenza (se extra UE) <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="certificatoResidenza"
                onChange={(e) => handleMultiFileChange(e, "certificatoResidenza")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="unilav">
                Documento Unilav <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <input
                type="file"
                name="documentoUnilav"
                onChange={(e) => handleMultiFileChange(e, "documentoUnilav")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />

              {isMaster ? (<h2>Azienda:</h2>) : null}
              {isMaster ? (<Typography></Typography>) : null}
              {isMaster ?
                (
                  <FormControl>
                    <Autocomplete
                      id="azienda"
                      value={formValues.azienda}
                      onChange={(event, newValue) => {
                        setFormValues({
                          ...formValues,
                          azienda: newValue,
                        });
                        setAziendaId(newValue);
                      }}
                      options={aziende.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = aziende.filter(az => az.id == id)[0];
                        return `${a.nome} (${a.id})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Azienda"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                ) : null
              }
              {isMaster ? (<Typography></Typography>) : null}
            </Box>

            <Typography textAlign="center" color="red" my={1}>
              *Allegare questi documenti se l'assunzione non è stata fatta da questo Studio
            </Typography>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection="column"
              px={2}
            >
              {statusMessage && (
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    mt: 2,
                    flexGrow: 1,
                    backgroundColor: "#e57373",
                    borderColor: "#d32f2f",
                    color: "black",
                    width: "100%",
                  }}
                  role="alert"
                >
                  {statusMessage}
                </Paper>
              )}
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                px={2}
              >
                {loading ? (
                  <CircularProgress sx={{ mt: 2 }} />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, px: 8 }}
                  >
                    Invia
                  </Button>
                )}
              </Box>
            </Box>
          </LocalizationProvider>
        </form>
      </Box>
    </Container>
  );
};

export default PagMalattia;
