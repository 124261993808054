import React, { useState, useEffect } from "react";
import {
  Paper, Typography, Container, CircularProgress, Grid, TextField, Button, Link, Divider, Box,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

const DettagliAzienda = () => {
  const [searchParams] = useSearchParams();
  const [azienda, setAzienda] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAzienda, setEditedAzienda] = useState({});
  const [statusMessage, setStatusMessage] = useState('');
  const [etichette, setEtichette] = useState([]);

  const id = searchParams.get("id");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          try {
            const res = await axios.get(`/api/getuser?id=${id}`, {
              headers: utils.getAuthHeaders(),
            });
            const { success } = res.data;
            if (success) {
              setAzienda(res.data.user);
              setEditedAzienda(res.data.user);
              setEtichette(res.data.user.etichette.split(',').filter(e => e.length));
            }
          } catch (error) {
            console.log(error);
            setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
          }
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
    setIsLoading(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAzienda({ ...editedAzienda, [name]: value });
  };

  const handleConfirmClick = async () => {
    setStatusMessage("");
    try {
      const res = await axios.post(`/api/updateutente`, { ...editedAzienda, id, etichette }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
  };

  const handleAddEtichetta = () => {
    setEtichette([...etichette, ""]);
  };

  const handleRemoveEtichetta = (index) => {
    const newEtichette = [...etichette];
    newEtichette.splice(index, 1);
    setEtichette(newEtichette);
  };

  const handleEtichettaChange = (e, index) => {
    const newEtichette = [...etichette];
    newEtichette[index] = e.target.value;
    setEtichette(newEtichette);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 2, mt: 2 }}>
        Dettagli Azienda
      </Typography>
      <Paper elevation={4} sx={{ borderRadius: "16px", padding: "24px", my: 1 }}>
        {isLoading ? (
          <CircularProgress disableShrink />
        ) : (
          <Box>
            <Grid container spacing={3}>
              {/* Colonna 1 */}
              <Grid item xs={12} sm={6}>
                {[
                  { label: "Nome", value: "nome" },
                  { label: "Ragione Sociale", value: "ragione_sociale" },
                  { label: "Telefono", value: "telefono" },
                  { label: "Città", value: "citta" },
                  { label: "CAP", value: "cap" },
                  { label: "Email", value: "email" },
                ].map(({ label, value }, index) => (
                  <React.Fragment key={index}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {label}:
                    </Typography>
                    {isEditing ? (
                      <TextField
                        name={value}
                        value={editedAzienda[value] || ""}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        sx={{ borderRadius: "8px" }}
                      />
                    ) : (
                      <Typography variant="body1">{azienda[value] || "-"}</Typography>
                    )}
                    <Divider sx={{ my: 2 }} />
                  </React.Fragment>
                ))}
              </Grid>

              {/* Colonna 2 */}
              <Grid item xs={12} sm={6}>
                {[
                  { label: "Cognome", value: "cognome" },
                  { label: "Partita IVA", value: "partita_iva" },
                  { label: "Indirizzo", value: "indirizzo" },
                  { label: "Provincia", value: "provincia" },
                  { label: "PEC", value: "pec" },
                  { label: "Commercialista", value: "commercialista" },
                ].map(({ label, value }, index) => (
                  <React.Fragment key={index}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {label}:
                    </Typography>
                    {isEditing ? (
                      <TextField
                        name={value}
                        value={editedAzienda[value] || ""}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        sx={{ borderRadius: "8px" }}
                      />
                    ) : (
                      <Typography variant="body1">{azienda[value] || "-"}</Typography>
                    )}
                    <Divider sx={{ my: 2 }} />
                  </React.Fragment>
                ))}
              </Grid>

              {/* Etichetta */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Etichetta:</Typography>
                {isEditing ? (
                  <Grid container spacing={0}>
                    {etichette.map((etichetta, index) => (
                      <Grid item xs={12} key={index}>
                        <Box mb={2} display="flex" alignItems="center">
                          <TextField
                            name={`etichetta-${index}`}
                            value={etichetta}
                            onChange={(e) => handleEtichettaChange(e, index)}
                            fullWidth
                            variant="outlined"
                            sx={{ borderRadius: "8px" }}
                          />
                          <Button onClick={() => handleRemoveEtichetta(index)} sx={{ ml: 1 }}>
                            <RemoveIcon />
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button onClick={handleAddEtichetta} variant="text" sx={{ borderRadius: "8px", mb: 2 }}>
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body1">{etichette.join(", ") || "Nessuna etichetta"}</Typography>
                )}
                <Divider sx={{ my: 1 }} />
              </Grid>

              {/* Master */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Utente Master:</Typography>
                {isEditing ? (
                  <Typography variant="body1">{editedAzienda.is_master ? "Sì" : "No"}</Typography>
                ) : (
                  <Typography variant="body1">{azienda.is_master ? "Sì" : "No"}</Typography>
                )}
                {azienda.master && (
                  <>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Master:</Typography>
                    <Typography variant="body1">
                      <Link href={`/op/dettaglioazienda?id=${azienda.master}`} sx={{ color: "primary.main" }}>
                        {azienda.masterName || "Master non disponibile"}
                      </Link>
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                  </>
                )}
              </Grid>

              {/* Conferma */}
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                {isEditing ? (
                  <Button onClick={handleConfirmClick} variant="contained" color="primary" sx={{ borderRadius: "8px", marginTop: "16px" }}>
                    Conferma
                  </Button>
                ) : (
                  <Button onClick={handleEditClick} variant="contained" color="primary" sx={{ borderRadius: "8px", marginTop: "16px" }}>
                    Modifica
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        {statusMessage && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {statusMessage}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default DettagliAzienda;
