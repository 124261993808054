import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, FormControl, Autocomplete, TextField, Tooltip, CircularProgress } from "@mui/material";
import axios from "axios";
import utils from "../../utils";

const SelectDipendente = ({ setFormValues, aziendaId }) => {
  const newDipendente = { id: 0, cognome: 'Nuovo dipendente', nome: '' };
  const [loading, setLoading] = useState(true);
  const [dipendente, setDipendente] = useState(newDipendente);
  const [dipendenti, setDipendenti] = useState([newDipendente]);

  useEffect(() => {
    loadDipendenti();
  }, [aziendaId]);

  const loadDipendenti = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/dipendenti?id=' + (aziendaId || 0), {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setDipendenti([newDipendente, ...res.data.dipendenti]);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  if (loading) {
    return <Box
      borderRadius={5}
      border="1px solid #ccc"
      padding={3}
      marginX="auto"
      marginBottom={2}
      maxWidth={620}
    ><CircularProgress sx={{ m: 2 }} /></Box>
  }

  return (
    <Box
      borderRadius={5}
      border="1px solid #ccc"
      padding={3}
      marginX="auto"
      marginBottom={2}
      maxWidth={620}
    >
      {/* Titolo e Divider */}
      <Typography variant="h6" component="h2" textAlign="center" fontWeight="bold">
        Seleziona Dipendente
      </Typography>
      
      <Divider sx={{ my: 2 }} />

      {/* Selettore Dipendente */}
      <FormControl fullWidth>
        <Tooltip title="Seleziona un dipendente per compilare automaticamente i campi">
          <Autocomplete
            id="dipendente"
            value={dipendente}
            onChange={(event, newValue) => {
              if (newValue && newValue.id) {
                setFormValues(formValues => { return {
                  ...formValues,
                  nome: newValue.nome,
                  cognome: newValue.cognome,
                  codiceFiscale: newValue.cod_fiscale,
                  dataNascita: newValue.data_nascita,
                }});
              }
              setDipendente(newValue || newDipendente);
            }}
            options={dipendenti}
            getOptionLabel={(dip) => `${dip.cognome} ${dip.nome}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Dipendente"
                variant="outlined"
              />
            )}
          />
        </Tooltip>
      </FormControl>
    </Box>
  );
};

export default SelectDipendente;
