import React, { useEffect, useState } from 'react';
import {
  Box, Container, Typography, Autocomplete, TextField, Grid, Select, MenuItem, FormControl, InputLabel, OutlinedInput,
} from '@mui/material';
import OpContrattTab from './OpContrattTab';
import axios from 'axios';
import utils from '../../utils';

const PraticheArchiviate = () => {
  const [aziende, setAziende] = useState([]);
  const [selectedAzienda, setSelectedAzienda] = useState(-1);
  const [incaricati, setIncaricati] = useState([]);
  const [selectedIncaricato, setSelectedIncaricato] = useState(-1);

  useEffect(() => {
    loadAziendeEOp();
  }, []);

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        setIncaricati(res.data.operatori);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h4" margin={2} align="center" fontWeight="bold" gutterBottom>
          Pratiche Completate
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Autocomplete
              id="azienda"
              value={selectedAzienda}
              onChange={(event, newValue) => {
                setSelectedAzienda(newValue);
              }}
              options={[-1, ...aziende.map((az) => az.id)]}
              getOptionLabel={(id) => {
                if (id == -1) {
                  return 'Tutte le Aziende';
                }
                const a = aziende.find(az => az.id === id);
                return a ? `${a.nome} (${a.id})` : '';
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Azienda"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Incaricato</InputLabel>
              <Select
                value={selectedIncaricato}
                onChange={(e) => setSelectedIncaricato(e.target.value)}
                input={<OutlinedInput label="Incaricato" />}
              >
                <MenuItem key={-1} value={-1}>Tutti gli OP</MenuItem>
                {incaricati.map((incaricato) => (
                  <MenuItem key={incaricato.id} value={incaricato.id}>
                    {incaricato.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <OpContrattTab dafare={false} azienda={selectedAzienda} op={selectedIncaricato} />
      </Box>
    </Container>
  );
};

export default PraticheArchiviate;
