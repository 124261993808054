import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Stack,
  Container,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Autocomplete
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackIos,
  ArrowForwardIos,
  GetApp,
  CloudUpload,
  CheckCircle,
  Cancel,
  Info,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";
import JSZip from "jszip";

const OpEntiArc = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({ nome: "", user: 0, data: "", cs: "" });
  const [selectedFileDate, setSelectedFileDate] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [azienda, setAzienda] = useState(-1);
  const [aziende, setAziende] = useState([{ id: -1, nome: 'Tutte' }]);
  const [openPricePopup, setOpenPricePopup] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showPrezzo, setShowPrezzo] = useState(false);
  const rowsPerPage = 8;
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [infoFields, setInfoFields] = useState({
    inps: "",
    inail: "",
    cassaEdile: "",
    note: "",
    noteProssimoDurc: ""
  });

  useEffect(() => {
    loadTickets(0, -1);
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const tuttiAzienda = [{ id: -1, nome: 'Tutte' }];
        tuttiAzienda.push(...res.data.aziende);
        setAziende(tuttiAzienda);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadTickets = async (p, az) => {
    try {
      const res = await axios.get(`/api/durcsarchivio?page=${p}&id=${az > 0 ? az : ''}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets.map(t => { return { ...t, prezzo: t.prezzo && (Number(t.prezzo) / 100).toFixed(2) } }));
        setShowPrezzo(res.data.tickets.length ? typeof res.data.tickets[0].prezzo == 'number' : false);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const CellStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  };

  const centerText = {
    textAlign: "center",
  };

  const dataRowSX = {
    display: "table-row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  };

  const renderTableRow = (row) => {
    const today = new Date();
    const scadenzaDate = new Date(row.dataScadenza);

    // Verifica se il DURC è scaduto o scadrà entro 15 giorni
    const isExpired = scadenzaDate < today;
    const isExpiringSoon = scadenzaDate >= today && (scadenzaDate - today) / (1000 * 60 * 60 * 24) <= 15;

    // Definisci gli stili condizionali
    const rowStyle = {
      backgroundColor: isExpired ? 'rgba(255, 99, 71, 0.5)' : isExpiringSoon ? 'rgba(255, 165, 0, 0.5)' : 'inherit', // Rosso acceso per scaduti, arancione acceso per in scadenza entro 15 giorni
    };

    return (
      <TableRow key={row.id} sx={rowStyle}>
        <TableCell style={centerText}>{row.nome}</TableCell>
        <TableCell style={centerText}>{utils.revDate(row.dataRichiesta)}</TableCell>
        <TableCell style={centerText}>{utils.revDate(row.dataCreazione)}</TableCell>
        <TableCell style={centerText}>{utils.revDate(row.dataScadenza)}</TableCell>
        <TableCell style={centerText}>
          <Button
            onClick={() => downloadDoc(row.docDurc)}
            variant="contained"
            startIcon={<GetApp />}
            sx={{ color: 'white', backgroundColor: 'green' }}
          >
            Scarica
          </Button>
        </TableCell>

        {/*x ora disabilitiamo l'aggiornamento di doc e data*/}
        {/* <TableCell style={centerText}>
          <Button
            variant="contained"
            startIcon={<CloudUpload />}
            sx={{ color: 'white', backgroundColor: '#1976d2' }}
            onClick={() => openUploadDialog({ nome: row.nome, user: row.user, data: row.dataRichiesta, cs: row.dataScadenza })}
          >
            Carica
          </Button>
        </TableCell> */}

        <TableCell style={centerText}>
          {row.concesso ? (
            <CheckCircle style={{ color: 'green' }} />
          ) : (
            <Cancel style={{ color: 'red' }} />
          )}
        </TableCell>

        <TableCell style={centerText}>
          <IconButton
            onClick={() => handleOpenInfoDialog(row)}
            color="primary"
          >
            <Info />
          </IconButton>
        </TableCell>

        {showPrezzo && <TableCell style={centerText}>
          <Typography
            variant="body1"
            style={{ cursor: 'pointer' }}
            onClick={() => { setSelectedRowId(row.id); setOpenPricePopup(true); }}
          >
            {row.prezzo !== undefined ? row.prezzo : 'Prezzo'}
          </Typography>
        </TableCell>}

        <Dialog
          open={openPricePopup && selectedRowId === row.id}
          onClose={() => {
            setOpenPricePopup(false);
            setSelectedRowId(null);
          }}
        >
          <DialogTitle>Prezzo</DialogTitle>
          <DialogContent>
            <TextField
              value={row.prezzo || ""}
              onChange={(e) => updateTicketPrice(row.id, e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenPricePopup(false);
                setSelectedRowId(null);
              }}
            >
              Annulla
            </Button>
            <Button
              onClick={() => saveTicketPrice(row.id)}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  };

  const handleOpenInfoDialog = (row) => {
    // Pre-popolare i campi di testo se i dati sono disponibili nel row
    setInfoFields({
      inps: row.info.inps || "",
      inail: row.info.inail || "",
      cassaEdile: row.info.cassaEdile || "",
      note: row.info.note || "",
      noteProssimoDurc: row.info.noteProssimoDurc || ""
    });
    setSelectedRowId(row.id);
    setInfoDialogOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setSelectedRowId(null);
    setInfoDialogOpen(false);
  };

  const updateTicketPrice = (id, price) => {
    const updatedTickets = tickets.map(ticket => {
      if (ticket.id === id) {
        return { ...ticket, prezzo: price };
      }
      return ticket;
    });
    setTickets(updatedTickets);
  };

  const saveTicketPrice = async (id) => {
    setStatusMessageTickets("");
    const ticket = tickets.find(ticket => ticket.id === id);
    const prezzoInt = Math.round(Number(ticket.prezzo.toString().replace(',', '.')) * 100);
    if (!prezzoInt) {
      setStatusMessageTickets("Valore non valido per il prezzo: " + ticket.prezzo);
      return;
    }
    setTicketLoading(true);
    try {
      const res = await axios.post(`/api/updateprezzoenti`, {
        id: ticket.id,
        tipo: 'durc',
        prezzo: prezzoInt
      }, {
        headers: utils.getAuthHeaders(),
      });
      if (res.data.success) {
        setOpenPricePopup(false);
        setSelectedRowId(null);
      } else {
        setStatusMessageTickets("Errore nel salvataggio del prezzo");
      }
    } catch (error) {
      setStatusMessageTickets("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage, azienda);
  };

  const openUploadDialog = (t) => {
    setSelectedFileDate(t.cs);
    setSelectedTicket(t);
    setUploadDialogOpen(true);
  };

  const closeUploadDialog = () => {
    setSelectedFileDate("");
    setUploadedFile(null);
    setSelectedTicket({ nome: "", user: 0, data: "", cs: "" });
    setUploadDialogOpen(false);
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    if (files.length == 1) {
      // per un file non genera lo zip, solo se sono piu di uno
      setUploadedFile({ name: files[0].name, file: files[0] });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setUploadedFile({ name: 'documenti.zip', zippedBlob });
    });
  }

  // Funzione per scaricare un documento dato l'ID
  const downloadDoc = async (docId) => {
    setStatusMessageTickets("");
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setStatusMessageTickets("File non trovato.");
    }
  };

  const handleConfirmChanges = async (e) => {
    e.preventDefault();
    setStatusMessageTickets("");
    setTicketLoading(true);
    try {
      const payload = new FormData();

      const json = {
        id: selectedTicket.id,
        userId: selectedTicket.user,
        dataRichiesta: selectedTicket.data,
        dataScadenza: selectedFileDate,
        docDurc: uploadedFile.name
      };

      if (uploadedFile) {
        payload.append("doc", uploadedFile.file || uploadedFile.zippedBlob);
      }

      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/aggiornadurc", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleUpdateInfo = async () => {
    setStatusMessageTickets("");
    setInfoLoading(true);
    try {
      const res = await axios.post(`/api/aggiornainfodurc`, {
        id: selectedRowId,
        info: infoFields,
      }, {
        headers: utils.getAuthHeaders(),
      });
      if (res.data.success) { } else {
        setStatusMessageTickets(res.data.error);
        handleCloseInfoDialog();
      }
    } catch (error) {
      setStatusMessageTickets("Si è verificato un errore imprevisto sul nostro server.");
      handleCloseInfoDialog();
      console.log(error);
    }
    setInfoLoading(false);
  };

  const handleAziendaChange = (newAzienda) => {
    setTicketLoading(true);
    setAzienda(newAzienda);
    setTicketPage(0);
    loadTickets(0, newAzienda);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
        Archivio Richieste Durc
      </Typography>

      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="azienda"
            value={azienda}
            onChange={(event, newValue) => {
              handleAziendaChange(newValue)
            }}
            options={aziende.map((az) => az.id)}
            getOptionLabel={(id) => {
              const a = aziende.filter(az => az.id == id)[0];
              if (a.id > 0) {
                return `${a.nome} (${a.id})`;
              }
              return a.nome;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Azienda"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      </Grid>

      {
        ticketLoading ? (
          <Stack sx={{ my: 3 }} alignItems="center">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <Box sx={{ overflowX: "auto" }}>
            <Paper sx={{ borderRadius: "10px", overflow: "hidden" }} variant="outlined">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={CellStyle}>Azienda</TableCell>
                      <TableCell style={CellStyle}>Data Richiesta</TableCell>
                      <TableCell style={CellStyle}>Data Creazione</TableCell>
                      <TableCell style={CellStyle}>Data Scadenza</TableCell>
                      <TableCell style={CellStyle}>Documenti</TableCell>
                      {/* <TableCell style={CellStyle}>Modifica</TableCell> */}
                      <TableCell style={CellStyle}>Concesso</TableCell>
                      <TableCell style={CellStyle}>Info</TableCell>
                      {showPrezzo && <TableCell style={CellStyle}>Prezzo</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>{tickets.map(renderTableRow)}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
              <Typography
                fontSize="1.1rem"
                sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
              >
                {`${ticketPage * rowsPerPage + 1} - ${ticketPage * rowsPerPage + tickets.length} di ${ticketTotal}`}
              </Typography>
              <IconButton
                onClick={() => handleTicketPage(false)}
                disabled={ticketPage === 0}
              >
                <ArrowBackIos />
              </IconButton>
              <IconButton
                onClick={() => handleTicketPage(true)}
                disabled={rowsPerPage * (ticketPage + 1) >= ticketTotal}
              >
                <ArrowForwardIos />
              </IconButton>
            </Stack>
            {(statusMessageTickets) && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  my: 1,
                  mx: 2,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                }}
                role="alert"
              >
                {statusMessageTickets}
              </Paper>
            )}
          </Box>
        )
      }

      {/* Dialog per il caricamento */}
      <Dialog open={uploadDialogOpen} onClose={closeUploadDialog}>
        <DialogTitle>Modifica DURC</DialogTitle>
        <DialogContent>
          <Typography>Nuova data di SCADENZA</Typography>
          <TextField
            type="date"
            value={selectedFileDate}
            onChange={(e) => setSelectedFileDate(e.target.value)}
            fullWidth
            required
          />
          <input
            type="file"
            onChange={handleMultiFileChange}
            style={{ marginTop: "1rem" }}
            multiple
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadDialog}>Annulla</Button>
          <Button
            onClick={handleConfirmChanges}
            variant="contained"
            color="primary"
            disabled={!uploadedFile || !selectedFileDate}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per le info */}
      <Dialog open={infoDialogOpen} onClose={handleCloseInfoDialog}>
        <DialogTitle>Informazioni Supplementari</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="INPS"
                value={infoFields.inps}
                onChange={(e) => setInfoFields({ ...infoFields, inps: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="INAIL"
                value={infoFields.inail}
                onChange={(e) => setInfoFields({ ...infoFields, inail: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cassa Edile"
                value={infoFields.cassaEdile}
                onChange={(e) => setInfoFields({ ...infoFields, cassaEdile: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <TextField
            label="Note"
            value={infoFields.note}
            onChange={(e) => setInfoFields({ ...infoFields, note: e.target.value })}
            fullWidth
            multiline
            rows={5}
            margin="normal"
          />
          <TextField
            label="Note Prossimo DURC"
            value={infoFields.noteProssimoDurc}
            onChange={(e) => setInfoFields({ ...infoFields, noteProssimoDurc: e.target.value })}
            fullWidth
            multiline
            rows={5}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog}>Chiudi</Button>
          <LoadingButton loading={infoLoading} variant="contained" onClick={handleUpdateInfo}>
            Salva
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container >
  );
};

export default OpEntiArc;
