import React, { useState, useEffect, useRef } from 'react';
import { TextareaAutosize, IconButton, Paper, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/system';

const Memo = styled(Paper)(({ theme }) => ({
  width: 200,
  height: 200,
  background: '#ffffcc',
  borderRadius: 10,
  boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)',
  border: 'none',
  position: 'absolute',
  cursor: 'pointer',
  padding: 10, // Margine generale
}));

const MemoTitle = styled(TextareaAutosize)(({ theme }) => ({
  width: 'calc(100% - 20px)',
  height: 20,
  fontWeight: 'bold',
  fontSize: 16,
  padding: '5px 10px',
  border: 'none',
  resize: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
}));

const MemoContent = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 40px)',
  border: 'none',
  overflow: 'hidden',
}));

const MemoContentTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  resize: 'none',
  fontFamily: 'Arial, sans-serif',
  backgroundColor: 'transparent',
  padding: 10,
  outline: 'none',
}));

const ColorSelector = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: -28,
  left: '50%',
  transform: 'translateX(-50%)',
}));

const ColorOption = styled('div')(({ theme }) => ({
  width: 20,
  height: 20,
  margin: 5,
  cursor: 'pointer',
  borderRadius: '50%',
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: -33,
  right: -15,
  fontSize: 17,
  color: '#888',
  cursor: 'pointer',
  display: 'none',
  '&:hover': {
    color: '#f00',
  },
}));

const AddMemoButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: 80,
  right: 20,
  backgroundColor: 'transparent',
  color: '#4CAF50',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  fontSize: 36,
  '&:hover': {
    backgroundColor: 'rgba(76, 175, 80, 0.2)',
  },
}));

const TrashButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: 80,
  left: 20,
  backgroundColor: 'transparent',
  color: '#f00',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  fontSize: 36,
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
  },
}));

const App = () => {
  const [memos, setMemos] = useState([]);
  const [deletedMemos, setDeletedMemos] = useState([]);
  const [draggedMemo, setDraggedMemo] = useState(null);
  const [zIndexOrder, setZIndexOrder] = useState(1);
  const [openTrashDialog, setOpenTrashDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [initialPosition, setInitialPosition] = useState(null);
  const animationFrameId = useRef(null);

  const createMemo = () => {
    const newMemo = {
      id: Date.now(),
      position: { x: 50, y: 50 },
      backgroundColor: '#ffffcc',
      zIndex: zIndexOrder,
      title: '',
    };
    setMemos([...memos, newMemo]);
    setZIndexOrder(zIndexOrder + 1);
  };

  const handleMouseDown = (event, id) => {
    const memo = memos.find(m => m.id === id);
    setInitialPosition({ x: memo.position.x, y: memo.position.y });
    setDraggedMemo({ id, offsetX: event.clientX, offsetY: event.clientY });
    setMemos(memos.map(memo =>
      memo.id === id ? { ...memo, zIndex: zIndexOrder } : memo
    ));
    setZIndexOrder(zIndexOrder + 1);
  };

  const handleMouseMove = (event) => {
    if (draggedMemo && initialPosition) {
      const deltaX = event.clientX - draggedMemo.offsetX;
      const deltaY = event.clientY - draggedMemo.offsetY;

      setMemos((prevMemos) =>
        prevMemos.map((memo) =>
          memo.id === draggedMemo.id
            ? {
              ...memo,
              position: {
                x: initialPosition.x + deltaX,
                y: initialPosition.y + deltaY,
              },
            }
            : memo
        )
      );
    }
  };

  const handleMouseUp = () => {
    setDraggedMemo(null);
    setInitialPosition(null);
  };

  const handleColorChange = (color, memo) => {
    const newMemos = memos.map((m) =>
      m.id === memo.id ? { ...m, backgroundColor: color } : m
    );
    setMemos(newMemos);
  };

  const handleClose = (memo) => {
    setMemos(memos.filter((m) => m.id !== memo.id));
    setDeletedMemos([...deletedMemos, memo]);
  };

  const restoreMemo = (memo) => {
    setMemos([...memos, memo]);
    setDeletedMemos(deletedMemos.filter((m) => m.id !== memo.id));
  };

  const emptyTrash = () => {
    setOpenConfirmDialog(true);
  };

  const confirmEmptyTrash = () => {
    setDeletedMemos([]);
    setOpenConfirmDialog(false);
  };

  const cancelEmptyTrash = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <div
      style={{
        position: 'relative',
        width: 'auto',
        height: 'auto',
        backgroundColor: '#f2f2f2',
      }}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {memos.map((memo) => (
        <Memo
          key={memo.id}
          style={{ top: memo.position.y, left: memo.position.x, backgroundColor: memo.backgroundColor, zIndex: memo.zIndex }}
          onMouseDown={(e) => handleMouseDown(e, memo.id)}
          onMouseEnter={() => {
            const colorSelector = document.querySelector(`#color-selector-${memo.id}`);
            const closeIcon = document.querySelector(`#close-icon-${memo.id}`);
            if (colorSelector) {
              colorSelector.style.display = 'flex';
            }
            if (closeIcon) {
              closeIcon.style.display = 'block';
            }
          }}
          onMouseLeave={() => {
            const colorSelector = document.querySelector(`#color-selector-${memo.id}`);
            const closeIcon = document.querySelector(`#close-icon-${memo.id}`);
            if (colorSelector) {
              colorSelector.style.display = 'none';
            }
            if (closeIcon) {
              closeIcon.style.display = 'none';
            }
          }}
        >
          <MemoTitle
            value={memo.title}
            onChange={(e) => {
              const newMemos = memos.map((m) =>
                m.id === memo.id ? { ...m, title: e.target.value } : m
              );
              setMemos(newMemos);
            }}
            placeholder="Titolo"
          />
          <MemoContent>
            <MemoContentTextarea placeholder="scrivi..." />
          </MemoContent>
          <ColorSelector id={`color-selector-${memo.id}`}>
            {['#ffffcc', '#ffcccc', '#ccffcc', '#ccccff', '#ccffff', '#ffccff'].map((color) => (
              <ColorOption
                key={color}
                style={{ backgroundColor: color }}
                onClick={() => handleColorChange(color, memo)}
              />
            ))}
          </ColorSelector>
          <CloseIconButton id={`close-icon-${memo.id}`} onClick={() => handleClose(memo)}>
            <CloseIcon />
          </CloseIconButton>
        </Memo>
      ))}
      <Tooltip title="Aggiungi memo">
        <AddMemoButton onClick={createMemo}>
          <AddIcon />
        </AddMemoButton>
      </Tooltip>
      <Tooltip title="Cestino">
        <TrashButton onClick={() => setOpenTrashDialog(true)}>
          <DeleteIcon />
        </TrashButton>
      </Tooltip>

      <Dialog open={openTrashDialog} onClose={() => setOpenTrashDialog(false)}>
        <DialogTitle style={{ textAlign: 'center' }}>Cestino</DialogTitle>
        <DialogContent>
          <List>
            {deletedMemos.map((memo) => (
              <ListItem key={memo.id}>
                <IconButton
                  style={{ padding: 0, marginRight: '10px' }}
                  size="medium"
                >
                  <FiberManualRecordIcon style={{ color: memo.backgroundColor }} />
                </IconButton>
                <ListItemText
                  primary={memo.title || 'Senza titolo'}
                />
                <IconButton onClick={() => restoreMemo(memo)}>
                  <RestoreIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>

        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={() => setOpenTrashDialog(false)} color="primary">
            Chiudi
          </Button>
          <Button onClick={emptyTrash} color="primary">
            Svuota
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={cancelEmptyTrash}>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          Sei sicuro di voler svuotare il cestino?
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEmptyTrash} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmEmptyTrash} color="primary">
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default App;
