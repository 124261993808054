import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Button, IconButton, TextField, Drawer, Typography, Avatar, Badge, Paper, Divider, useTheme,
  useMediaQuery, Fab, AppBar, Toolbar, CircularProgress, List, ListItem, ListItemAvatar, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import {
  Send as SendIcon, Chat as ChatIcon, AttachFile as AttachFileIcon, PhotoCamera as PhotoCameraIcon,
  Mic as MicIcon, ArrowBack as ArrowBackIcon, Close as CloseIcon, Stop as StopIcon, Shortcut as ShortcutIcon,
  Add as AddIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import utils from '../utils';

const Chat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [activeChat, setActiveChat] = useState(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const mediaRecorderRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isForwarding, setIsForwarding] = useState(false);
  const [openForwardDialog, setOpenForwardDialog] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [expanded, setExpanded] = useState(false);
  // Gestione Chat Recenti e Contatti Espandibili
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const intervalId = setInterval(() => {
      loadChats();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [userId])

  const loadUser = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success) {
          setUserId(me.id);
          setUsername(me.op ? me.nome : me.ragione_sociale);
          setRole(me.op ? 'operator' : 'client');
          setTimeout(() => loadChats(), 500);
        }
      }
    } catch (_) { }
  }

  {/* Per simulare login Op E testare in locale */ }
  {/*const loadUser = async () => {
    try {
      // Simula il login di un operatore
      const mockUser = {
        id: 1,
        nome: "Operatore",
        op: true, // Indica che l'utente è un operatore
      };

      setUserId(mockUser.id);
      setUsername(mockUser.nome);
      setRole(mockUser.op ? 'operator' : 'client');
      setTimeout(() => loadChats(), 500);
    } catch (_) { }
  }; */}

  const loadChats = async () => {
    try {
      const res = await axios.get(`/api/chats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setClients(res.data.chats.sort((a, b) => Number(b.lastMessageAt) - Number(a.lastMessageAt)));
        const unreadCount = res.data.chats.map(c => c.unreadCount).reduce((a, b) => a + b, 0);
        setUnreadCount(unreadCount);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedMessages([]); // Reset selected messages when closing the chat
    }
  }, [isOpen]);

  const fetchMessages = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get('/api/chat', {
        params: { to: activeChat.id }, headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(res.data.messaggi);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let intervalId;
    if (activeChat) {
      fetchMessages();
      intervalId = setInterval(fetchMessages, 3000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeChat]);

  // Funz Invio Messaggi
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    if (isLoading) return;

    const payload = {
      testo: message,
      to: activeChat.id,
    };

    const formdata = new FormData();
    formdata.append("data", JSON.stringify(payload));

    setMessage("");
    setIsLoading(true); // Start loading

    try {
      const res = await axios.post('/api/chat', formdata, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        setMessages(prev => [...prev, {
          nome: username,
          utente_id: userId,
          testo: payload.testo,
          index: messages.length ? messages[messages.length - 1].index + 1 : 0,
          timestamp: Date.now()
        }]);
      } else {
        console.error(error);
      }
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funz Invio Immagini
  const handleSendImage = async (file) => {
    if (!file) return;
    if (isLoading) return;

    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      setIsLoading(true);

      const compressedFile = await imageCompression(file, options);

      const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      const base64Image = await toBase64(compressedFile);

      const payload = {
        testo: base64Image,
        to: activeChat.id,
        tipo: 'image',
      };

      const formdata = new FormData();
      formdata.append("data", JSON.stringify(payload));

      try {
        const res = await axios.post('/api/chat', formdata, {
          headers: {
            ...utils.getAuthHeaders(),
            "Content-Type": "multipart/form-data",
          },
        });
        const { success, error } = res.data;
        if (success) {
          setMessages(prev => [...prev, {
            nome: username,
            utente_id: userId,
            testo: payload.testo,
            tipo: 'image',
            index: messages.length ? messages[messages.length - 1].index + 1 : 0,
            timestamp: Date.now()
          }]);
        } else {
          console.error(error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error sending image:', error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error compressing or converting image to base64:', error);
    }
  };

  // Funz Allega File
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const newMessage = {
      id: Date.now(),
      type: 'file',
      fileName: file.name,
      fileSize: file.size,
      fileUrl: URL.createObjectURL(file),
      senderId: username,
      timestamp: new Date().toISOString(),
    };

    setMessages(prev => [...prev, newMessage]);
    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append('data', JSON.stringify({
      testo: '',
      chat_id: activeChat.id,
      file: file.name
    }));
    formData.append('file', file);

    try {
      await axios.post('/chat', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setIsLoading(false); // End loading after successful send
    } catch (error) {
      console.error('Error sending file:', error);
      setIsLoading(false); // End loading on error
    }
  };

  // Funzione reg Audio
  const handleVoiceRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const newMessage = {
          id: Date.now(),
          type: 'audio',
          audioUrl,
          senderId: username,
          timestamp: new Date().toISOString(),
        };
        setMessages(prev => [...prev, newMessage]);
        setIsLoading(true); // Start loading

        const formData = new FormData();
        formData.append('data', JSON.stringify({
          testo: '',
          chat_id: activeChat.id,
          file: 'audio.wav'
        }));
        formData.append('file', audioBlob);

        axios.post('/chat', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          setIsLoading(false); // End loading after successful send
        }).catch(error => {
          console.error('Error sending audio:', error);
          setIsLoading(false); // End loading on error
        });
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error recording audio:', error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const MessageBubble = ({ message }) => {
    const isOwn = message.utente_id === userId;
    const isSelected = selectedMessages.includes(message.index);

    const toggleSelectMessage = () => {
      setSelectedMessages(prev =>
        prev.includes(message.index)
          ? prev.filter(id => id !== message.index)
          : [...prev, message.index]
      );
    };

    if (role !== 'operator' && role !== 'client') {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: isOwn ? 'flex-end' : 'flex-start',
          mb: 2,
          alignItems: 'center', // Align icons with message
        }}
        onClick={toggleSelectMessage} // Toggle selection on click
      >
        {!isOwn && (
          <Avatar sx={{ mr: 1 }}>
            {message.nome[0].toUpperCase()}
          </Avatar>
        )}

        {/* Bubble Message della chat */}
        <Paper
          elevation={1}
          sx={{
            maxWidth: '70%',
            p: 2,
            backgroundColor: isOwn ? '#008B8B' : '#6495ED', // Colore di sfondo per mittente e destinatario
            color: isOwn ? 'white' : 'inherit', // Colore del testo
            borderRadius: 2,
            position: 'relative',
          }}
        >
          {message.tipo === 'text' && (
            <Typography>{message.testo}</Typography>
          )}
          {message.tipo === 'image' && (
            <img
              src={`data:image/jpeg;base64,${message.testo}`}
              alt="Immagine inviata"
              style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px' }} // Stile opzionale
            />
          )}

          {/* {message.type === 'audio' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '200px' }}>
              <audio controls style={{ width: '100%' }}>
                <source src={message.audioUrl} type="audio/wav" />
              </audio>
            </Box>
          )}
          {message.type === 'file' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AttachFileIcon fontSize="small" />
              <Typography
                component="a"
                href={message.fileUrl}
                download={message.fileName}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {message.fileName}
              </Typography>
            </Box>
          )} */}

          <Typography variant="caption" sx={{ display: 'block', mt: 0.5, opacity: 0.7 }}>
            {new Date(Number(message.timestamp)).toLocaleTimeString()}
          </Typography>

          {isSelected && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              right: isOwn ? '105%' : '-40px', // Adjust position based on sender
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
              <IconButton
                color="primary"
                size="small"
                disabled={true}
                onClick={() => setOpenForwardDialog(true)} // Open the forward dialog
              >
                <ShortcutIcon />
              </IconButton>
            </Box>
          )}
        </Paper>
      </Box>
    );
  };

  // Funzione Per Inoltrare i Messaggi
  const handleForwardMessages = (targetChat) => {
    const forwardedMessages = selectedMessages.map(id => {
      const message = messages.find(msg => msg.id === id);
      return {
        ...message,
        id: Date.now(), // Assign new unique id for forwarded message
        senderId: username, // New sender (the operator)
        timestamp: new Date().toISOString(),
      };
    });

    if (targetChat.id === activeChat.id) {
      setMessages(prev => [...prev, ...forwardedMessages]);
    }
    // Reset state
    setSelectedMessages([]);
    setIsForwarding(false);
    setOpenForwardDialog(false); // Close the dialog
  };

  const handleOpenChat = async (cl) => {
    setActiveChat(cl);
    setClients(prevClients =>
      prevClients.map(c =>
        c.id === cl.id ? { ...c, unreadCount: 0 } : c
      )
    );
    const unreadCount = clients.map(c => c.id === cl.id ? 0 : c.unreadCount).reduce((a, b) => a + b, 0);
    setUnreadCount(unreadCount);
    try {
      const res = await axios.post('/api/chatletta', { to: cl.id }, {
        headers: utils.getAuthHeaders()
      });
      const { success, error } = res.data;
      if (!success) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const openChat = () => {
    if (role == 'client') {
      if (clients[0]) {
        setIsOpen(true);
        handleOpenChat(clients[0]);
      }
    } else {
      setIsOpen(true);
    }
  }

  const closeChat = () => {
    setIsOpen(false);
    setActiveChat(null);
  }

  const filteredClients = clients.filter(a => a.name.toLowerCase()
    .replace(/[^\p{L}\p{N}]/gu, '')
    .includes(searchTerm.toLowerCase().replace(/[^\p{L}\p{N}]/gu, '')));

  // Chat Recenti e Contatti Espandibili
  const recentChats = filteredClients.filter(client => client.lastMessageAt > Date.now() - 7 * 24 * 60 * 60 * 1000); // Chat degli ultimi 7 giorni
  const otherContacts = filteredClients.filter(client => client.lastMessageAt <= Date.now() - 7 * 24 * 60 * 60 * 1000); // Altri contatti

  if (!userId) {
    return null;
  }

  return (
    <>
      {/* Icona della Chat Cliccabile per aprirla */}
      <Fab
        color="primary"
        size="small"
        onClick={() => openChat()}
        sx={{
          position: 'fixed',
          bottom: 65,
          right: 10,
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <ChatIcon />
        </Badge>
      </Fab>

      {/* Corpo Chat */}
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={isOpen}
        onClose={() => closeChat()}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : isExpanded ? '100%' : 700, // Wider on desktop
            height: isMobile ? '100%' : '100vh',
            borderRadius: isMobile ? 0 : 4, // Rounded corners only on desktop
          }
        }}
      >
        {/* Barra alta della chat */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <AppBar
            position="static"
            // color="primary"
            sx={{
              backgroundColor: '#008B8B', // Colore di sfondo della barra di stato
              color: 'white', // Colore del testo all'interno della barra di stato
            }}
          >
            <Toolbar>
              {(role != 'client' || null) && activeChat && (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setActiveChat(null)}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, flexGrow: 1 }}>
                <Avatar sx={{ mr: 2 }}>
                  {username && username[0].toUpperCase()}
                </Avatar>
                <Typography variant="h6">
                  {activeChat ? activeChat.name : username}
                </Typography>
              </Box>
              <IconButton color="inherit" onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton color="inherit" onClick={() => closeChat()}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {/* Only the contact list will have the search box */}
          {activeChat === null ? (
            <Box sx={{ p: 2, borderRadius: 1, bgcolor: theme.palette.grey[100] }}>
              {role === 'operator' && <TextField
                placeholder="Cerca contatti"
                variant="outlined"
                onChange={handleSearch}
                fullWidth
                InputProps={{
                  sx: {
                    border: 'none', // Remove borders
                    '& .MuiOutlinedInput-root': {
                      border: 'none', // Remove input borders
                      borderRadius: '4px', // Keep rounded corners
                    },
                  },
                }}
              />}
              {role === 'operator' && <Divider sx={{ my: 2 }} />}
              <List sx={{ overflow: 'auto', flexGrow: 1 }}>

                {/* Chat Recenti */}
                <Accordion expanded={expanded === 'recentChats'} onChange={handleChange('recentChats')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold' }}>Chat Recenti</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {recentChats.map((client) => (
                        <ListItem
                          key={client.id}
                          onClick={() => handleOpenChat(client)}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar>{client.name[0]}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {client.name}
                                {client.unreadCount > 0 && (
                                  <Badge
                                    badgeContent={client.unreadCount}
                                    color="error"
                                    sx={{ ml: 2 }} // Add spacing between name and badge
                                  >
                                    <span></span>
                                  </Badge>
                                )}
                              </Box>
                            }
                            secondary={<Box
                              component="span"
                              sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {client.lastMessage}
                            </Box>}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>

                {/* Altri Contatti */}
                <Accordion expanded={expanded === 'otherContacts'} onChange={handleChange('otherContacts')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold' }}>Altri Contatti</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {otherContacts.map((client) => (
                        <ListItem
                          key={client.id}
                          onClick={() => handleOpenChat(client)}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            }
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar>{client.name[0]}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {client.name}
                                {client.unreadCount > 0 && (
                                  <Badge
                                    badgeContent={client.unreadCount}
                                    color="error"
                                    sx={{ ml: 2 }} // Add spacing between name and badge
                                  >
                                    <span></span>
                                  </Badge>
                                )}
                              </Box>
                            }
                            secondary={<Box
                              component="span"
                              sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {client.lastMessage}
                            </Box>}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>

                {/* per ora omettiamo */}
                {/* {role === 'operator' && (
                  <ListItem
                    onClick={() => setActiveChat('operators')}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>O</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Studio Romeo"
                      secondary="Gruppo Studio"
                    />
                  </ListItem>
                )} */}

                {/* Parte per i Contatti trovati */}
                {role === 'operator' && filteredClients.length == 0 &&
                  <Typography variant="body2" sx={{ textAlign: 'center', color: theme.palette.text.secondary }}>
                    Nessun contatto trovato
                  </Typography>}
              </List>
            </Box>
          ) : (
            <>

              {/* Corpo della chat tra barra e campo invio messaggi*/}
              <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2, backgroundColor: theme.palette.grey[50] }}>
                {messages.map((msg) => (
                  <MessageBubble key={msg.index} message={msg} />
                ))}
                <div ref={messagesEndRef} />
              </Box>

              {/* Paper Invio Messaggi Audio File ecc */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  <IconButton
                    color="primary"
                    disabled={true}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={isLoading}
                    onClick={() => document.getElementById('image-upload').click()}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        handleSendImage(e.target.files[0]);
                      }
                    }}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Scrivi un messaggio..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.altKey) {
                        handleSendMessage();
                      } else if (e.key === 'Enter' && e.altKey) {
                        e.preventDefault();
                        setTimeout(() => {
                          setMessage(prevMessage => prevMessage + '\n');
                        }, 50);
                      }
                    }}
                    variant="outlined"
                    multiline
                    minRows={1}
                    maxRows={4}
                    InputProps={{
                      style: {
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        resize: 'none',
                      },
                    }}
                  />

                  <IconButton
                    color={isRecording ? 'error' : 'primary'}
                    disabled={true}
                    onClick={handleVoiceRecord}
                  >
                    {isRecording ? <StopIcon /> : <MicIcon />}
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!message.trim()}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                  </IconButton>
                </Box>
              </Paper>
            </>
          )}
        </Box>

        {/* Inoltro Messaggi */}
        <Dialog
          open={openForwardDialog}
          onClose={() => setOpenForwardDialog(false)}
        >
          <DialogTitle>Inoltra Messaggi</DialogTitle>
          <DialogContent>
            <List>
              {clients.map(client => (
                <ListItem
                  key={client.id}
                  button
                  onClick={() => {
                    handleForwardMessages(client); // Forward messages to selected contact
                    setOpenForwardDialog(false); // Close dialog after forwarding
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>{client.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={client.name} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenForwardDialog(false)} color="secondary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>

      </Drawer>
    </>
  );
};

export default Chat;
