import React, { useState, useEffect } from 'react';
import {
  Container, Typography, FormControl, TextField, Autocomplete, Table, TableBody, Grid,
  TableCell, TableContainer, TableHead, TableRow, Button, Paper, Stack, CircularProgress, Tooltip
} from '@mui/material';
import {
  CheckCircleOutline,
  HighlightOffOutlined,
} from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import utils from "../../utils";


const AvvisiParcelle = () => {
  const [aziendaSelezionata, setAziendaSelezionata] = useState(null);
  const [avvisi, setAvvisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [aziende, setAziende] = useState([]);

  useEffect(() => {
    loadAziende();
  }, []);

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadData = async (id) => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.get(`/api/avvisiparcella?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAvvisi(res.data.avvisi);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleChange = (nuovaAzienda) => {
    setAziendaSelezionata(nuovaAzienda);
    loadData(nuovaAzienda);
  };

  const handleCreaAvviso = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/createavvisoparcella`, { id: aziendaSelezionata }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, id } = res.data;
      if (success) {
        return window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + id;
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleRowClick = (avviso) => {
    window.location.href = '/op/amministrazione/avvisiparcella/detail?id=' + avviso.id;
  };

  return (
    <Container maxWidth="xxl">
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom sx={{ mt: 2 }}>
        Avvisi di Parcella
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} sm={10}>
          <FormControl fullWidth>
            <Autocomplete
              id="azienda"
              value={aziendaSelezionata}
              onChange={(event, newValue) => handleChange(newValue)}
              options={aziende.map((az) => az.id)}
              getOptionLabel={(id) => {
                const a = aziende.filter(az => az.id === id)[0];
                return `${a.nome} (${a.id})`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Azienda"
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center">
          {aziendaSelezionata && (
            <Tooltip title="Crea Avviso di Parcella per tutte le pratiche associate a questa azienda">
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={handleCreaAvviso}
              >
                Crea Avviso
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {loading ? <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack> : <div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Numero</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Periodo</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '20%' }}>Ragione Sociale</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '15%' }}>Data Scadenza</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '15%' }}>Data Pagamento</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }}>Importo</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }} align="center">Confermato</TableCell>
                <TableCell sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', width: '10%' }} align="center">Pagato</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {avvisi.map((avviso) => (
                <TableRow key={avviso.id} onClick={() => handleRowClick(avviso)} style={{ cursor: 'pointer' }}>
                  <TableCell>{avviso.numero_cp}</TableCell>
                  <TableCell>{avviso.periodo}</TableCell>
                  <TableCell>{avviso.ragione_sociale}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_scadenza)}</TableCell>
                  <TableCell>{utils.revDate(avviso.data_pagamento)}</TableCell>
                  <TableCell>{(Number(avviso.tot_a_pagare) / 100).toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {avviso.confermato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {avviso.pagato ? (
                      <CheckCircleOutline color="success" />
                    ) : (
                      <HighlightOffOutlined color="error" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {statusMessage && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              backgroundColor: "#e57373",
              borderColor: "#d32f2f",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}
      </div>}
    </Container>
  );
};

export default AvvisiParcelle;
