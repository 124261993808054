import React, { useState, useEffect } from 'react';
import {
  Divider, Select, Grid, FormControl, InputLabel, MenuItem, Stack, CircularProgress, Paper, Typography, Container, TextField, Badge,
  Link,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import utils from "../../utils";
import { Link as RouterLink } from 'react-router-dom';
import OpAmmPraticheArchiviate from './OpAmmPraticheArchiviate';
import OpPagheFatte from './OpPagheFatte';

const OperatorPractices = () => {
  const [operator, setOperator] = useState(0);
  const [operatori, setOperatori] = useState([{ id: 0, nome: 'Tutti' }]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [totPratiche, setTotPratiche] = useState({ contrattualistica: 0, presenze: 0, enti: 0 });
  const [fatturato, setFatturato] = useState(0);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    loadOps();
  }, []);

  const loadOps = async () => {
    try {
      const res = await axios.get(`/api/listaop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, ops } = res.data;
      if (success) {
        setOperatori([{ id: 0, nome: 'Tutti' }, ...ops]);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const loadData = async (op, start, end) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/opstats?op=${op}&start=${start}&end=${end}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, stats } = res.data;
      if (success) {
        setTotPratiche({ contrattualistica: stats.totContrattualistica, presenze: stats.totPresenze, enti: stats.totEnti });
        setFatturato((stats.totFatturato / 100).toFixed(2));
        setChartData(Object.keys(stats.grafico).map(k => { return { k, tot: stats.grafico[k] } }));
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handleOperatorChange = (event) => {
    const { value } = event.target;
    setOperator(value);
    if (startDate && endDate) {
      loadData(value, startDate, endDate);
    }
  };

  const handleStartDateChange = (date) => {
    if (!date) {
      setStartDate(null);
      return;
    }
    if (date.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = date.$D.toString().padStart(2, "0");
    const month = (date.$M + 1).toString().padStart(2, "0");
    const year = date.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    if (endDate) {
      loadData(operator, formattedDate, endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setEndDate(null);
      return;
    }
    if (date.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = date.$D.toString().padStart(2, "0");
    const month = (date.$M + 1).toString().padStart(2, "0");
    const year = date.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setEndDate(formattedDate);
    if (startDate) {
      loadData(operator, startDate, formattedDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography variant="h5" margin={3} align="center" fontWeight="bold" gutterBottom>
          Operazioni Svolte da Op
        </Typography>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>
                  Seleziona Operatore
                </InputLabel>
                <Select
                  value={operator}
                  onChange={handleOperatorChange}
                  label="Seleziona operatore"
                >
                  {operatori.map((az) => (
                    <MenuItem key={az.id} value={az.id}>{az.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Data Inizio"
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDateChange}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Data Fine"
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '12px 0' }} />
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Stack sx={{ my: 3 }} alignItems="center">
                  <CircularProgress disableShrink />
                </Stack>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 10, left: 10, bottom: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="k" angle={-45} textAnchor="end" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="tot" fill="#0d6efd" />
                  </BarChart>
                </ResponsiveContainer>)}
            </Grid>

            {/* Contenitore per la tabella, con scrolling */}
            <Grid item xs={12}>
              <Paper elevation={2} style={{ maxHeight: '400px', overflowY: 'auto', padding: '20px' }}>
                <OpAmmPraticheArchiviate />
              </Paper>
            </Grid>

            {/* Contenitore per la tabella, con scrolling */}
            <Grid item xs={12}>
              <Paper elevation={2} style={{ maxHeight: '400px', overflowY: 'auto', padding: '20px' }}>
              <OpPagheFatte />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '12px 0' }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" gutterBottom marginBottom={3}>
                Pratiche Totali
              </Typography>
              <Grid container justifyContent="center" spacing={2} sx={{ marginBottom: '20px' }}>
                <Grid item sx={{ width: '150px', textAlign: 'center' }}>
                  <Badge color="primary" badgeContent={<strong>{totPratiche.contrattualistica}</strong>}>
                    <Link component={RouterLink} to="/op/amministrazione/pratichearchiviate" underline="none">
                      <Typography sx={{ margin: '5px', textDecoration: 'none' }}>Contrattualistica</Typography>
                    </Link>
                  </Badge>
                </Grid>
                <Grid item sx={{ width: '150px', textAlign: 'center' }}>
                  <Badge color="primary" badgeContent={<strong>{totPratiche.presenze}</strong>}>
                    <Link component={RouterLink} to="/op/paghe/paghefatte" underline="none">
                      <Typography sx={{ margin: '5px', textDecoration: 'none' }}>Paghe</Typography>
                    </Link>
                  </Badge>
                </Grid>
                <Grid item sx={{ width: '150px', textAlign: 'center' }}>
                  <Badge color="primary" badgeContent={<strong>{totPratiche.enti}</strong>}>
                    <Link component={RouterLink} to="/op/enti/" underline="none">
                      <Typography sx={{ margin: '5px', textDecoration: 'none' }}>Enti</Typography>
                    </Link>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '12px 0' }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" align="center" gutterBottom marginBottom={3}>
                Fatturato Operatore
              </Typography>
              <Typography variant="h6" align="center" gutterBottom marginBottom={3}>
                {fatturato}€
              </Typography>
            </Grid>

            {statusMessage && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  ml: 3,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessage}
              </Paper>
            )}

          </Grid>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default OperatorPractices;
