import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Container, Typography, TextField, Button, Paper, FormControlLabel, Checkbox, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75vh',
  },
  form: {
    width: '300px',
    marginTop: '1rem',
  },
  submitButton: {
    marginTop: '0.5rem',
    padding: '0.75rem 1rem',
    borderRadius: '20px',   
    fontSize: '1rem',       
    fontWeight: 'bold',   
    textTransform: 'none',
  },
};

const Login = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("backto") || null;
  const emailParam = searchParams.get("email") || '';
  const error = searchParams.get("error") || '';

  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState('');
  const [useCookieStorage, setUseCookieStorage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [statusMessage, setStatusMessage] = useState(error);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('');
    try {
      const res = await axios.post('/api/login', { email, password, stayLogged: useCookieStorage });
      const { success, error, token, isOp } = res.data;
      if (success) {
        sessionStorage.setItem('jwt', token);
        sessionStorage.setItem('usertype', isOp ? 'op' : 'user');
        if (useCookieStorage) {
          const expirationDate = new Date('2038-01-01');
          const cookieValue = token + `; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `jwt=${cookieValue}`;
        }
        window.location.href = redirect || (isOp ? '/op' : '/cli');
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage('Si è verificato un errore imprevisto sul nostro server.');
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={styles.container}>
      <Typography variant="h4" component="h1">
        Login
      </Typography>
      <form style={styles.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={useCookieStorage}
              onChange={() => setUseCookieStorage(!useCookieStorage)}
            />
          }
          label="Ricordami"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={styles.submitButton}
        >
          Login
        </Button>
      </form>
      {statusMessage && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            my: 4,
            backgroundColor: '#e57373',
            borderColor: '#d32f2f',
            color: 'black',
          }}
          role="alert"
        >
          {statusMessage}
        </Paper>
      )}
    </Container>
  );
};

export default Login;
