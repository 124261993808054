import React, { useState } from "react";
import { Button, Typography, Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import CliAmmContabilita from "./CliAmmContabilita";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  [theme.breakpoints.up("md")]: {
    marginLeft: "10px",
    margin: "10px",
    textAlign: "left",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const buttonStyles = {
  width: "100%",
  marginBottom: "10px",
  transition: "transform 0.2s ease, filter 0.2s ease",
  background: "#333", // Colore di sfondo scuro predefinito
  color: "white", // Testo bianco predefinito
};

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "250px",
  padding: "20px",
  borderRadius: "10px",
  background: "linear-gradient(to bottom, #1e1e1e, #353535)",
  boxShadow: "0px 0px 5px 0px #888888",
  color: "white",
  margin: "10px auto",
  [theme.breakpoints.up("md")]: {
    marginLeft: "10px", 
    margin: "10px",
    textAlign: "left",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const CliAmm = () => {
  const [hoveredAvvisi, setHoveredAvvisi] = useState(false);
  const [hoveredFatture, setHoveredFatture] = useState(false);
  // const [hoveredDipendenti, setHoveredDipendenti] = useState(false);

  return (
    <Container>
      <DashboardContainer>
        <Typography variant="h5" align="center" gutterBottom mt={0}>
          Amministrazione
        </Typography>

        <Link
          to="/cli/amministrazione/avvisiparcella"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredAvvisi ? "orange" : "#333", // Giallo al passaggio del mouse
              transform: hoveredAvvisi ? "scale(1.05)" : "scale(1)",
              filter: hoveredAvvisi ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredAvvisi(true)}
            onMouseLeave={() => setHoveredAvvisi(false)}
          >
            Avvisi di Parcella
          </Button>
        </Link>

        <Link to="/cli/amministrazione/fatture" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredFatture ? "green" : "#333", // Verde al passaggio del mouse
              transform: hoveredFatture ? "scale(1.05)" : "scale(1)",
              filter: hoveredFatture ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredFatture(true)}
            onMouseLeave={() => setHoveredFatture(false)}
          >
            Fatture
          </Button>
        </Link>

       {/* <Link to="/cli/amministrazione/dipendenti" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            style={{
              ...buttonStyles,
              background: hoveredDipendenti ? "blue" : "#333", // Blu al passaggio del mouse
              transform: hoveredDipendenti ? "scale(1.05)" : "scale(1)",
              filter: hoveredDipendenti ? "brightness(1.2)" : "brightness(1)",
            }}
            onMouseEnter={() => setHoveredDipendenti(true)}
            onMouseLeave={() => setHoveredDipendenti(false)}
          >
            Dipendenti
          </Button>
        </Link>*/}
      </DashboardContainer>
      {/* tabella stato dei pagamenti <CliAmmContabilità /> */}
      <CliAmmContabilita />
    </Container>
  );
};

export default CliAmm;
