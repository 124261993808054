import React, { useState, useEffect } from "react";
import {
  TextField, Button, Input, Box, Container, Typography, Paper, CircularProgress, FormControl, MenuItem,
  InputLabel, Select, Autocomplete, Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import JSZip from 'jszip';
import axios from "axios";
import utils from "../../utils";
import ContrSelectDipendente from './ContrSelectDipendente';

const ContrContestazione = ({ op }) => {
  const [formValues, setFormValues] = useState({
    nome: "",
    cognome: "",
    dataNascita: "",
    codiceFiscale: "",
    dataEvento: null,
    motivoContestazione: "",
    contestazioneNumero: "",
    cartaIdentita: null,
    codiceFiscaleUpload: null,
    permessoSoggiorno: null,
    certificatoResidenza: null,
    documentoUnilav: null,
    altriDocumenti: null,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [aziende, setAziende] = useState([]);
  const [aziendaId, setAziendaId] = useState(0);
  const [isMaster, setIsMaster] = useState(false);
  const [operatori, setOperatori] = useState([{ id: 0, nome: 'Caricamento...' }]);

  // Effetto per il caricamento dei dati iniziali
  useEffect(() => {
    loadData();
    if (op) {
      loadAziendeEOp();
    }
  }, []);

  // Funzione per il caricamento dei dati iniziali
  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && op && !me.op) {
          window.location.href = "/cli";
          return;
        } if (success) {
          setIsMaster(me.is_master);
          if (me.is_master) {
            loadSottoAziende();
          }
          setLoading(false);
          return; // Rimani sulla pagina
        } else if (error) {
          setStatusMessage(error);
        }
      }
    } catch (error) {
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        setOperatori(res.data.operatori);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadSottoAziende = async () => {
    try {
      const res = await axios.get(`/api/sottoaziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  // Funzione per la gestione dei cambiamenti negli input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Funzione per la gestione dei cambiamenti nelle date
  const handleDateChange = (name, newValue) => {
    if (!newValue) {
      setFormValues({
        ...formValues,
        [name]: null,
      });
      return;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };

  // Funzione per la gestione dei file
  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    setFormValues({
      ...formValues,
      [name]: file,
    });
  };

  const handleMultiFileChange = (e, name) => {
    const files = e.target.files;

    if (files.length == 1) {
      setFormValues({
        ...formValues,
        [name]: { name: files[0].name, file: files[0] },
      });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setFormValues({
        ...formValues,
        [name]: { name: `${name}.zip`, file: zippedBlob },
      });
    });
  }

  // Funzione per la gestione delle selezioni
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Funzione per la gestione dell'invio del modulo
  const handleSubmit = async (e) => {
    setStatusMessage("");
    e.preventDefault();

    if (!formValues.dataEvento) {
      setStatusMessage("Inserire data evento");
      return;
    }

    const json = {
      tipo: "contestazione",
      nome: formValues.nome,
      cognome: formValues.cognome,
      cod_fiscale: formValues.codiceFiscale,
      data_nascita: formValues.dataNascita,
      data_evento: formValues.dataEvento,
      contestazione_numero: formValues.contestazioneNumero,
      motivo_contestazione: formValues.motivoContestazione,
      doc_altro: formValues.altriDocumenti
        ? formValues.altriDocumenti.name
        : null,
      doc_carta: formValues.cartaIdentita
        ? formValues.cartaIdentita.name
        : null,
      doc_cf: formValues.codiceFiscaleUpload
        ? formValues.codiceFiscaleUpload.name
        : null,
      doc_permesso: formValues.permessoSoggiorno
        ? formValues.permessoSoggiorno.name
        : null,
      doc_residenza: formValues.certificatoResidenza
        ? formValues.certificatoResidenza.name
        : null,
      doc_unilav: formValues.documentoUnilav
        ? formValues.documentoUnilav.name
        : null,

      azienda: formValues.azienda,
      in_carico: formValues.inCarico,
    };

    const payload = new FormData();

    if (formValues.cartaIdentita) {
      payload.append("carta", formValues.cartaIdentita.file);
    }
    if (formValues.codiceFiscaleUpload) {
      payload.append("cf", formValues.codiceFiscaleUpload.file);
    }
    if (formValues.permessoSoggiorno) {
      payload.append("ps", formValues.permessoSoggiorno.file);
    }
    if (formValues.certificatoResidenza) {
      payload.append("cr", formValues.certificatoResidenza.file);
    }
    if (formValues.documentoUnilav) {
      payload.append("ul", formValues.documentoUnilav.file);
    }
    if (formValues.altriDocumenti) {
      payload.append("altro", formValues.altriDocumenti.file);
    }

    payload.append("data", JSON.stringify(json));

    try {
      setLoading(true);
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setLoading(false);
      if (success) {
        if (op) {
          window.location.href = "/success?backto=%2Fop%2Fcontrattualistica";
        } else {
          window.location.href = "/success";
        }
      } else {
        setStatusMessage(error);
        return;
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 413) {
        setStatusMessage(
          "I files sono troppo grandi. Il limite massimo è di 100 MB."
        );
      } else {
        setStatusMessage(
          "Si è verificato un errore imprevisto sul nostro server."
        );
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mt={3}>
        Pratica di Contestazione
      </Typography>

      {/* Selettore Dipendente */}
      <ContrSelectDipendente setFormValues={setFormValues} aziendaId={aziendaId} />

      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <form className="contestazione-form" onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              display="grid"
              gap={2}
              borderRadius={5}
              border="1px solid #ccc"
              padding={3}
              marginX="auto"
              gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
            >
              <TextField
                label="Nome"
                type="text"
                name="nome"
                value={formValues.nome}
                onChange={handleInputChange}
                required
                title="Inserisci il tuo nome"
              />
              <TextField
                label="Cognome"
                type="text"
                name="cognome"
                value={formValues.cognome}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Data di Nascita"
                type="text"
                name="dataNascita"
                value={formValues.dataNascita}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Codice Fiscale"
                type="text"
                name="codiceFiscale"
                value={formValues.codiceFiscale}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Contestazione Numero"
                type="text"
                name="contestazioneNumero"
                value={formValues.contestazioneNumero}
                onChange={handleInputChange}
              />
              <DatePicker
                label="Data Evento *"
                format="DD/MM/YYYY"
                value={formValues.dataEvento}
                onChange={(newValue) =>
                  handleDateChange("dataEvento", newValue)
                }
                required
              />

              <TextField
                label="Motivo Contestazione"
                type="text"
                name="motivoContestazione"
                value={formValues.motivoContestazione}
                onChange={handleInputChange}
                multiline
                rows={5}
                required
              />

              <div></div>
              <h2>Documenti</h2>
              <div></div>

              <label htmlFor="cartaIdentita">
                Carta d'Identità <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="cartaIdentita"
                onChange={(e) => handleMultiFileChange(e, "cartaIdentita")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="codiceFiscaleUpload">
                Codice Fiscale <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="codiceFiscaleUpload"
                onChange={(e) => handleMultiFileChange(e, "codiceFiscaleUpload")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="permessoSoggiorno">
                Permesso di Soggiorno (se extra UE){" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="permessoSoggiorno"
                onChange={(e) => handleMultiFileChange(e, "permessoSoggiorno")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="certificatoResidenza">
                Certificato di Residenza (se extra UE){" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="certificatoResidenza"
                onChange={(e) => handleMultiFileChange(e, "certificatoResidenza")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="unilav">
                Documento Unilav <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <input
                type="file"
                name="documentoUnilav"
                onChange={(e) => handleMultiFileChange(e, "documentoUnilav")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="altriDocumenti">
                Altri Documenti (Eventuali Prove)
              </label>
              <input
                type="file"
                name="altriDocumenti"
                onChange={(e) => handleMultiFileChange(e, "altriDocumenti")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />

              {op ? (<h2>Azienda e incaricato:</h2>) : null}
              {isMaster ? (<h2>Azienda:</h2>) : null}
              {(op || isMaster) ? (<Typography></Typography>) : null}
              {(op || isMaster) ?
                (
                  <FormControl>
                    <Autocomplete
                      id="azienda"
                      value={formValues.azienda}
                      onChange={(event, newValue) => {
                        setFormValues({
                          ...formValues,
                          azienda: newValue,
                        });
                        setAziendaId(newValue);
                      }}
                      options={aziende.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = aziende.filter(az => az.id == id)[0];
                        return `${a.nome} (${a.id})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Azienda"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                ) : null
              }
              {op ?
                (
                  <FormControl>
                    <InputLabel id="select-op-label">Operatore</InputLabel>
                    <Select
                      labelId="inCarico-label"
                      id="inCarico"
                      name="inCarico"
                      value={formValues.inCarico}
                      onChange={handleSelectChange}
                      required
                      label="Operatore"
                    >
                      {operatori.map((ope) => (
                        <MenuItem key={ope.id} value={ope.id}>{ope.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              }
              {isMaster ? (<Typography></Typography>) : null}
            </Box>
          </LocalizationProvider>

          <Typography textAlign="center" color="red" my={1}>
            *Allegare questi documenti, fronte e retro, se l'assunzione non è stata fatta da questo Studio
          </Typography>

          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection="column"
            px={2}
          >
            {statusMessage && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  mt: 2,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                  width: "100%",
                }}
                role="alert"
              >
                {statusMessage}
              </Paper>
            )}
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              px={2}
            >
              {loading ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <Button type="submit" variant="contained" sx={{ mt: 2, px: 8 }}>
                  Invia
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ContrContestazione;
