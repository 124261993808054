import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Paper,
  Box
} from '@mui/material';
import axios from "axios";
import utils from "../../utils";

import { LoadingButton } from '@mui/lab';

const PrenotaCBA = () => {
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setError] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setOpen(false);
    setStatusMessage("");
    setLoading(true);

    try {
      const res = await axios.post(`/api/inviaemailcba`,
        {},
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        setError(false);
        setStatusMessage("Richiesta inviata con successo.");
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '80%', lg: '50%' },  // Responsivo per diversi breakpoint
          p: 3,  // Padding generale
          textAlign: 'center',  // Centra il contenuto
          boxShadow: 3,  // Aggiunge ombra per dare un effetto card
          backgroundColor: '#fff',  // Sfondo bianco per separarlo meglio
          borderRadius: 3,  // Angoli arrotondati
          margin: 1,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Prenota CBA
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ fontStyle: 'italic', color: 'gray', mb: 2 }}>
          In questa sezione puoi prenotare una sessione CBA. Cliccando il pulsante sottostante, invierai una richiesta per essere ricontattato da un operatore che gestirà la tua prenotazione.
        </Typography>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleClickOpen}>
          Prenota
        </LoadingButton>

        {statusMessage && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mt: 3,
              backgroundColor: isError ? "#e57373" : "#81c784",
              borderColor: isError ? "#d32f2f" : "#388e3c",
              color: "black",
            }}
            role="alert"
          >
            {statusMessage}
          </Paper>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Conferma Prenotazione</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sicuro di voler prenotare per essere ricontattato per una sessione CBA?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annulla
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>

  );
};

export default PrenotaCBA;
